/* eslint-disable */
import React, { Fragment, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { HistoryCard, HistoryCardContent } from './HistoryCard';
import { Dialog, Transition } from '@headlessui/react';
import IntroActivityActions from './IntroActivityActions';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Icon from './Icon';
import context from '../exports.json';
import AuthService from '../service/AuthService';

/**
 * Intro Activity Modal used to focus on an introduction.
 * @param {Object} props - The component props.
 * @param {Function} props.onPause - The function to pause the activity.
 * @param {Function} props.onEnd - The function to end the activity.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {Object} props.introduction - The introduction object.
 * @param {Function} props.onClose - The function to close the modal.
 */
/**
 * Intro Activity Modal used to focus on an introduction.
 */
function IntroActivityModal({
  onPause,
  onEnd,
  isOpen,
  introduction = {},
  onClose,
  onOpen,
}) {
  const cancelButtonRef = useRef(null);
  const userInfo = new AuthService(context.ApiGatewayUrl).getUserInfo();

  const renderFeedbackAttempts = (introduction) => {
    if (!introduction.feedback) {
      return (
        <HistoryCard
          title="Feedback Request"
          responses={introduction.responses}
          sendDate={moment(introduction.createdAt).add(2, 'weeks')}
          remindDate={moment(introduction.createdAt).add(4, 'weeks')}
        ></HistoryCard>
      );
    }

    return Object.values(introduction.feedback).map((feedbackAttempt) => {
      return (
        <HistoryCard
          title="Feedback Request"
          responses={introduction.responses}
          sendDate={moment(introduction.createdAt).add(2, 'weeks')}
          remindDate={moment(introduction.createdAt).add(4, 'weeks')}
          content={
            <div className="whitespace-nowrap">
              {feedbackAttempt.map((feedback) => {
                return (
                  <>
                    <HistoryCardContent feedback={feedback} />
                  </>
                );
              })}
            </div>
          }
        />
      );
    });
  };

  if (isOpen && introduction)
    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel id='intro-activity-modal' className="relative overflow-hidden text-left bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="float-right p-4">
                    <button type="button" onClick={onClose}>
                      <Icon
                        className="text-gray-600"
                        iconClass="text-gray-600 h-5 w-5"
                        icon={<XMarkIcon />}
                      />
                    </button>
                  </div>
                  <div className="container p-6 mx-auto space-y-3">
                    <h2 className="mb-4 text-lg font-bold">Intro Activity</h2>
                    <div className="mt-4 mb-2 border border-t-0 border-gray-200 rounded" />
                    <p className="font-bold text-black">
                      {introduction.recipients
                        .map((r) => `${r.firstName} ${r.lastName}`)
                        .join(' and ')}
                      !
                    </p>
                    <div className="flex w-24 mt-6 ">
                      {introduction.recipients.map((recipient, i) => (
                        <React.Fragment>
                          {i > 0 && (
                            <p className="pl-2 pr-2 text-gray-500">----</p>
                          )}

                          <div className="flex items-center justify-center w-6 h-6 text-white bg-green-600 rounded-full">
                            {recipient.firstName[0]}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                    {/* <p className="font-semibold">Status</p>
                    <p
                      className={`inline-block px-2 font-bold text-sm ${introduction.status.textDarkColor} ${introduction.status.lightColor} rounded-lg whitespace-nowrap`}
                    >
                      {introduction.status.text}
                    </p>
                    <p className="font-semibold">Actions</p>
                    <div className="grid grid-cols-2 space-x-4">
                      <IntroActivityActions
                        status={introduction.status}
                        onPause={onPause}
                        onEnd={onEnd}
                      />
                    </div> */}
                    <div className="mt-4 mb-2 border border-t-0 border-gray-200 rounded" />
                    <p>History</p>
                    <HistoryCard
                      title="Intro Email"
                      sendDate={moment(introduction.createdAt)}
                      content={
                        <div>
                          <h2 className="font-bold text-black">
                            Introduction Email
                          </h2>
                          <div className="mt-2">
                            <span className="font-bold text-sm text-blue-700">
                              From:
                            </span>{' '}
                            <a href="#" className="font-bold text-xs">
                              {userInfo.email}
                            </a>
                          </div>
                          <div>
                            <span className="font-bold text-sm text-blue-700">
                              To:
                            </span>{' '}
                            <a href="#" className="font-bold text-xs">
                              {introduction.recipients
                                .map((r) => `${r.firstName} ${r.lastName}`)
                                .join(', ')}
                            </a>
                          </div>
                          <div className="font-bold text-xs mt-2 text-green-700">
                            {introduction.subject}
                          </div>
                          <div className="mt-2 text-sm">
                            {introduction.body}
                          </div>
                        </div>
                      }
                    />
                    <div className="w-0 h-6 m-auto border border-gray-500 border-dashed " onLoad={onOpen? onOpen(): undefined}/>
                    {renderFeedbackAttempts(introduction)}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
}

IntroActivityModal.propTypes = {
  onPause: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  introduction: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default IntroActivityModal;
