import React from 'react';
import propTypes from 'prop-types';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import Icon from './Icon';
import arrowsIcon from '../icon/home/selector.svg';
import Pagination from './Pagination';
import TableItemsCounter from './TableItemsCounter';

/**
 * Sortable Table Component
 */
export default function SortableTable({ columns = [], rows, actionable }) {
  /**
   * Render Table Arrows
   */

  return (
    <>
      <div className="table-overflow-x-auto">
        <div className="relative shadow-md sm:rounded-lg space-y-6 responsive-table">
          <table className="text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                {columns.map((col, index) => (
                  <th scope="col" className="px-4 py-3" key={`${index + col}`}>
                    <div className="flex items-center">
                      {col}
                      {/* <a href="#">
                        <img
                          src={arrowsIcon}
                          height={17}
                          width={17}
                          alt="Icon"
                        />
                      </a> */}
                    </div>
                  </th>
                ))}

                {actionable && (
                  <th scope="col" className="px-6 py-3 bg-gray-50">
                    <div className="flex items-center">Action</div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr
                  className="overflow-auto bg-white border-b whitespace-nowrap"
                  key={`${index + row}`}
                >
                  {row.map((d, idx) => (
                    <td 
                    
                  id={`introduction-${index}-${idx}`}
                    className="px-4 py-4 text-black" key={`${idx + d}`}>
                      {d}
                    </td>
                  ))}

                  {actionable && (
                    <td className="px-6 py-4 text-black">
                      <Icon
                        iconClass="w-5 h-5"
                        className="text-gray-600"
                        icon={<EllipsisHorizontalIcon />}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="p-4 flex justify-between items-center pb-5">
        {/* <TableItemsCounter from={1} to={2} totalItems={100} /> */}
        {/* <Pagination rows={[1, 2, 3]} /> */}
      </div>
    </>
  );
}

SortableTable.defaultProps = {
  actionable: false,
};

SortableTable.propTypes = {
  columns: propTypes.array.isRequired,
  rows: propTypes.array.isRequired,
  actionable: propTypes.bool,
};
