import React from 'react';
import propTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Icon from './Icon';
import { handleToString } from '../helpers/helpers';
import Avatars from './Avatars';

/**
 * Renders a component for selecting contacts.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.selectedContacts - The selected contacts.
 * @param {Function} props.onContactRemove - The function to remove a contact.
 * @returns {JSX.Element} The rendered component.
 */
function SelectContacts({
  selectedContacts,
  onContactRemove,
  hideRemoveButtons,
}) {
  const { first, second } = selectedContacts;

  return (
    <div>
      <div>
        
      </div>
      <div className="select-contacts bg-emerald-100 text-emerald-700 rounded-lg p-4">
        <p className="select-contacts__label">
          Selected Contacts for Connection
        </p>
        <div className="select-contacts__search">
          <div className="select-contacts__search__input">
            {(first && first?.firstName) || first?.lastName
              ? handleToString(`${first?.firstName} ${first?.lastName}`)
              : 'Contact 1'}
            {hideRemoveButtons === false && selectedContacts.first && (
              <button
                onClick={() => onContactRemove(selectedContacts.first)}
                type="button"
                className="absolute -top-2 -right-2 rounded-full bg-red-500 hover:bg-red-200"
              >
                <Icon
                  icon={<XMarkIcon />}
                  iconClass="h-5 w-5 mx-auto"
                />
              </button>
            )}
          </div>
          <Avatars />
          <div className="select-contacts__search__input">
            {(selectedContacts.second && second?.firstName) || second?.lastName
              ? handleToString(`${second?.firstName} ${second?.lastName}`)
              : 'Contact 2'}

            {hideRemoveButtons === false && selectedContacts.second && (
              <button
                onClick={() => onContactRemove(selectedContacts.second)}
                type="button"
                className="select-contacts__search__input__remove"
              >
                <Icon
                  icon={<XMarkIcon />}
                  iconClass="h-5 w-5 select-contacts__search__input__remove__icon"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

SelectContacts.defaultProps = {
  hideRemoveButtons: false,
};

SelectContacts.propTypes = {
  selectedContacts: propTypes.object.isRequired,
  onContactRemove: propTypes.func.isRequired,
  hideRemoveButtons: propTypes.bool,
};

export default SelectContacts;
