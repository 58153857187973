/**
 * Status represents a state with associated colors and text properties.
 */
export default class Status {
  id: string;
  text: string;
  darkColor: string;
  textDarkColor: string;
  lightColor: string;
  textLightColor: string;
  /**
   * Constructor
   * @param {string} id - Unique identifier for the status.
   * @param {string} text - Human-readable status name.
   * @param {string} darkColor - Background color for dark themes.
   * @param {string} lightColor - Background color for light themes.
   * @param {string} textDarkColor - Text color for dark themes.
   * @param {string} textLightColor - Text color for light themes.
   */
  constructor(
    id: string,
    text: string,
    darkColor: string,
    lightColor: string,
    textDarkColor: string,
    textLightColor: string
  ) {
    this.id = id;
    this.text = text;
    this.darkColor = darkColor;
    this.textDarkColor = textDarkColor;
    this.lightColor = lightColor;
    this.textLightColor = textLightColor;
  }

  /**
   * Determines if this status can transition to another status.
   * @param {Status} otherStatus - The status to check transition to.
   * @returns {boolean} True if the transition is allowed; otherwise, false.
   */
  canTransitionTo(otherStatus: { id: string }) {
    if (!this.id || !otherStatus.id) {
      return false;
    }

    // Define allowed transitions in a constant map
    const TRANSITION_MAP = {
      [STATUS.PAUSED.id]: [
        STATUS.SUCCESSFUL.id,
        STATUS.IN_PROGRESS.id,
        STATUS.DEAD.id,
      ],
      [STATUS.IN_PROGRESS.id]: [
        STATUS.SUCCESSFUL.id,
        STATUS.PAUSED.id,
        STATUS.DEAD.id,
      ],
      [STATUS.DEAD.id]: [],
      [STATUS.SUCCESSFUL.id]: [],
    };

    // Check if the target status is a valid transition
    return TRANSITION_MAP[this.id]?.includes(otherStatus.id) || false;
  }
}

/**
 * Predefined statuses used across the application.
 */
export const STATUS = {
  SUCCESSFUL: new Status(
    "SUCCESSFUL",
    "Successful",
    "bg-green-500",
    "bg-green-200",
    "text-green-500",
    "text-green-100"
  ),

  DEAD: new Status(
    "DEAD",
    "Intro death",
    "bg-red-500",
    "bg-red-200",
    "text-red-500",
    "text-red-100"
  ),

  IN_PROGRESS: new Status(
    "IN_PROGRESS",
    "In progress",
    "bg-blue-500",
    "bg-blue-200",
    "text-blue-500",
    "text-blue-100"
  ),

  PAUSED: new Status(
    "PAUSED",
    "Paused",
    "bg-yellow-500",
    "bg-yellow-200",
    "text-yellow-500",
    "text-yellow-100"
  ),
};
