import React, { Fragment, useEffect, useState } from 'react';
import Congratulate from '../../icon/Congratulate.png';

import { XMarkIcon } from '@heroicons/react/24/solid';
import Icon from '../Icon';

interface Props {
  userData: any;
  onClick: () => void;
}
export default function CongratulateScreen({ userData, onClick }) {
  return (
    <>
      <div className="relative">
        {/* Close Button */}
        <button
          onClick={onClick}
          className="absolute top-4 right-4 text-2xl text-gray-600 hover:text-gray-900"
        >
          <Icon
            className="text-gray-600"
            iconClass="text-gray-600 h-5 w-5"
            icon={<XMarkIcon />}
          />
        </button>

        {/* Modal Content */}
        <div className="flex items-center w-fit mx-auto pt-8">
          <img src={Congratulate} alt="Congratulate" />
        </div>
        <div className="text-center text-2xl font-bold">
          <h2>
            Congratulations, {`${userData.firstName} ${userData.lastName}`}!
          </h2>
          <h2>You just made your first introduction.</h2>
        </div>
        <p className="text-center text-lg text-gray-700 pt-4">
          Wasn't that easy? Now, let's navigate to your dashboard.
        </p>
        <div className="w-full px-8">
          <button
            onClick={onClick}
            className="w-full bg-emerald-600 text-white h-10 my-6 rounded-lg box-border"
          >
            Proceed to Dashboard
          </button>
        </div>
      </div>
    </>
  );
}
