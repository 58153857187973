import { useEffect } from 'react';

/**
 * Custom hook to get filter table variables.
 */
const useGetFiltersTableVariables = (
  activeTab,
  searchBy,
  setVariables,
  setLoadingContacts,
  setLoadingIntros
) => {
  useEffect(() => {
    // if (!searchBy || searchBy.length <= 0) return;
    if (activeTab === 'contacts') {
      searchBy
        ? setVariables({
            filter: {
              or: [
                {
                  firstName: {
                    contains: searchBy,
                  },
                },
                {
                  lastName: {
                    contains: searchBy,
                  },
                },
                {
                  email: {
                    contains: searchBy,
                  },
                },
              ],
            },
            // limit: 3,
            nextToken: null,
          })
        : setVariables({});
      setLoadingContacts(true);
    }
    if (activeTab === 'intros') {
      searchBy
        ? setVariables({
            filter: {
              subject: {
                contains: searchBy,
              },
            },
            // limit: 3,
            nextToken: null,
          })
        : setVariables({});
      setLoadingIntros(true);
    }
  }, [searchBy, activeTab, setLoadingContacts, setLoadingIntros, setVariables]);
};

export default useGetFiltersTableVariables;
