import axios, { AxiosInstance } from "axios";
import { SendIntroduction } from "common";

export default class IntroductionService {
    apiUrl: string;
    axiosInstance: AxiosInstance;

    constructor(
        apiUrl: string,
        applyInterceptors: (axiosInstance: AxiosInstance) => void
      ) {
        this.apiUrl = apiUrl;
    
        // Create an Axios instance
        this.axiosInstance = axios.create({
          baseURL: this.apiUrl,
        });
    
        // Apply interceptors
        applyInterceptors(this.axiosInstance);
      }

      getToken = () => {
        return JSON.parse(localStorage.getItem('tokens') as string).id_token;
      };

      
      list = async (): Promise<any> => {
        const response = await this.axiosInstance.get('api/introduction', {
          headers: {
            Authorization: this.getToken(),
          },
        });
        return response.data;
      };

      create = async (request: SendIntroduction): Promise<any> => {
        const response = await this.axiosInstance.post('api/introduction', request, {
          headers: {
            Authorization: this.getToken(),
          },
        });
        return response.data;
      };

    changeStatus = () => {
        return {}
    }
}