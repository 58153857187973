/* eslint-disable prettier/prettier */

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Renders the HomeSearch component.
 *
 * @returns {JSX.Element} The rendered HomeSearch component.
 */
export default function SearchContacts({ onSearchChange }) {
  const [inputValue, setInputValue] = useState('');


  /**
   * Handles the key down event.
   *
   * @param {Event} e - The key down event.
   */

  /**
   * Handles the input change event.
   *
   * @param {Event} e - The input change event.
   */
  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputValue(text);
    if (!text) {
        onSearchChange('');
    }
    onSearchChange(text)
  };
  return (
    <form
      className="flex items-center border h-16 rounded-lg"
    >
      <MagnifyingGlassIcon className="w-5 h-5 text-gray-500 mx-2" />
      <input
        onChange={handleInputChange}
        type="text"
        name="search"
        placeholder="Search"
        className="flex-1 bg-transparent outline-none text-sm text-gray-700 placeholder-gray-500 bg-gray-400"
        value={inputValue}
      />
    </form>
  );
}
