import React from 'react';
import { useNavigate } from 'react-router';

import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/solid';
import Banner from '../icon/introduction/banner-introduction.png';
import Logo from '../icon/Logo.png';
import Footer from './Footer';

// eslint-disable-next-line require-jsdoc
export default function Introduction() {
  const navigate = useNavigate();
  /**
   * Handles the click event to navigate to the home page.
   */
  const handleNavigateToHome = () => {
    navigate('/app/home');
  };

  return (
    <div className="introduction">
      <div className="introduction__banner">
        <img src={Banner} alt="banner" className="introduction__banner__img" />
      </div>
      <div className="introduction__content mb-12">
        <h1 className="introduction__content__title">
          Connecting your favorite people, made easy.
        </h1>
        <p className="introduction__content__description">
          Let’s make your first introduction in <br /> three easy steps.
        </p>
        <img className="introduction__content__logo" src={Logo} alt="logo" />
        <div className="introduction__content__container-btn">
          <button
            type="button"
            onClick={handleNavigateToHome}
            className="button__primary"
          >
            <ChatBubbleBottomCenterIcon className="flex w-5 h-5 mr-2" />
            Let´s Start
          </button>
        </div>
      </div>
      <Footer hideLinks />
    </div>
  );
}
