import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import React from 'react';

/**
 * The Privacy Policy Component
 * @returns Privacy Policy Component
 *
 */
export default function PrivacyPolicy() {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy for Looop</h1>
      <p className="text-sm text-gray-500 mb-6">Last Updated: 2024-11-01</p>

      <p className="mb-4">
        Looop respects your privacy and is committed to protecting the
        information you share with us. This Privacy Policy explains how we
        collect, use, share, and protect your personal information when you use
        our introduction management software. By using Looop, you consent to the
        practices outlined in this Privacy Policy.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        1. Information We Collect
      </h2>
      <p className="mb-4">
        To provide our services, we collect certain information from you when
        you connect your Google Account to Looop. This includes:
      </p>
      <ul className="list-disc list-inside ml-4 mb-4">
        <li>
          <strong>Email Access:</strong> We collect information from your Gmail
          account, including the ability to read, compose, send, and manage your
          emails as needed for facilitating introductions on your behalf.
        </li>
        <li>
          <strong>Contacts:</strong> We access your contacts list to help
          streamline and personalize introductions.
        </li>
        <li>
          <strong>Introduction Details:</strong> Any information you input or
          save for managing introductions, such as custom messages or templates.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        2. How We Use Your Information
      </h2>
      <p className="mb-4">
        The information we collect is used solely to deliver the Looop service
        effectively and in line with user expectations. Specifically, we use
        your data to:
      </p>
      <ul className="list-disc list-inside ml-4 mb-4">
        <li>
          Facilitate introductions by sending emails on your behalf through
          Gmail.
        </li>
        <li>
          Organize and store relevant contact information to improve user
          experience.
        </li>
        <li>
          Customize the introduction process based on user-provided templates or
          preferences.
        </li>
      </ul>
      <p className="mb-4 italic">
        Note: We do not use your information for advertising, marketing, or
        other unrelated purposes.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        3. Data Sharing and Disclosure
      </h2>
      <p className="mb-4">
        <strong>Third-Party Services:</strong> We do not sell, lease, or share
        your information with third parties for marketing or advertising
        purposes. Your data is used only for facilitating introductions as part
        of the Looop service.
      </p>
      <p className="mb-4">
        <strong>Legal Requirements:</strong> We may disclose your information if
        required to do so by law or in response to valid requests by public
        authorities (e.g., a court or government agency).
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        4. Data Security and Retention
      </h2>
      <p className="mb-4">
        We prioritize the security of your data and apply industry-standard
        practices to protect it. We retain your data only as long as necessary
        to fulfill the purposes outlined in this policy or as required by law.
      </p>
      <div className="">
        <a
          href="/"
          type="button"
          className="bg-blue-700 text-white hover:bg-blue-500 border font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
        >
          <ChevronLeftIcon className="w-5 h-4" />
          Go Back
        </a>
      </div>
    </div>
  );
}
