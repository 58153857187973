import { AxiosInstance, AxiosError } from 'axios';
import AuthService from './AuthService';

export const addAuthInterceptor = (authService: AuthService) => {
  return (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => response, // Let successful responses pass through
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          try {
            // Call the AuthService to refresh the token
            await authService.refreshToken();
            
            // Retry the failed request
            const config = error.config;
            if (config) {
              config.headers = {
                ...config.headers,
                Authorization: authService.getToken(), // Update token
              } as any;
              return axiosInstance.request(config); // Retry the request
            }
          } catch (refreshError) {
            // If the refresh fails, reject the promise
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error); // Reject other errors
      }
    );
  };
};
