import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Modal Displayed when creating a contact
 */
export default function Input({
  inputChangeCallback,
  validationFunc,
  titleText,
  placeholderText,
  id,
  value,
  required,
  type,
  hideLabel,
}) {
  const [errorText, setErrorText] = useState(false);

  /**
   * Run the validation on a blur
   */
  const validate = () => {
    setErrorText(validationFunc());
  };
  return (
    <div className="">
      {hideLabel === false && (
        <label
          className="block mb-2 text-sm font-bold text-gray-700"
          htmlFor={id}
        >
          {`${titleText}`}
          {required === true && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      {type === 'input' && (
        <input
          onChange={(e) => {
            inputChangeCallback(e);
          }}
          onBlur={validate}
          className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none bg-gray-50 focus:outline-none focus:shadow-outline"
          id={id}
          type="text"
          placeholder={placeholderText}
          value={value}
        />
      )}

      {type === 'textarea' && (
        <textarea
          className={`w-full h-16 p-2 border rounded-md ${
            errorText !== false ? 'border-red-500' : 'border-gray-300'
          }`}
          onChange={(e) => {
            inputChangeCallback(e);
          }}
          onBlur={validate}
          id={id}
          type="text"
          value={value}
        />
      )}
      {errorText !== false && (
        <p className="text-red-500 text-xs italic">{`${titleText} ${errorText}`}</p>
      )}
    </div>
  );
}

Input.propTypes = {
  inputChangeCallback: PropTypes.func.isRequired,
  validationFunc: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool.isRequired,
  type: PropTypes.string,
  hideLabel: PropTypes.bool,
};

Input.defaultProps = {
  type: 'input',
  hideLabel: false,
  id: '',
  value: '',
};
