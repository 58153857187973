import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

/**
 * AddContactResponseModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {Function} props.onSubmit - The submit callback function.
 * @param {Function} props.onClose - The close callback function.
 * @param {Object} props.contact - The contact object.
 * @param {boolean} props.error - The error object.
 * @returns {JSX.Element} The AddContactResponseModal component.
 */
function AddContactResponseModal({
  isOpen,
  onSubmit,
  onClose,
  contact,
  error = false,
}) {
  const { firstName, lastName, email } = contact;
  const titleError = 'Error, the contact could not be added.';
  const titleSuccess = 'You have added a new contact!';
  const subtitleError = 'Please try again.';
  const subtitleSuccess = `${firstName || ''} ${lastName || ''} `;
  const descriptionError = 'There was an error adding the contact.';
  const descriptionSuccess =
    "If you need to make changes to this contact, you can edit their record via the contacts table (look for the ... under the Actions column, next to said contact's existing record information).";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="add-contact-response">
        <h1 className="add-contact-response__title">
          {error ? titleError : titleSuccess}
        </h1>
        <h4 className="add-contact-response__subtitle">
          {error ? subtitleError : subtitleSuccess}
        </h4>
        <h4 className="add-contact-response__subtitle">{error ? '' : email}</h4>
        <div className="add-contact-response__description">
          <div className="mt-1 mb-5 border border-t-1 border-gray-200 rounded" />
          <h2 className="add-contact-response__description__text">
            {error ? descriptionError : descriptionSuccess}
          </h2>
        </div>
        <button type="button" className="button__primary" onClick={onClose}>
          Ok
        </button>
      </div>
    </Modal>
  );
}

AddContactResponseModal.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  error: false,
};

AddContactResponseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  contact: PropTypes.object.isRequired,
  error: PropTypes.bool,
};

export default AddContactResponseModal;
