import React from 'react';

import Avatar from '../icon/home/intro-avatar.svg';
import Line from '../icon/home/line.svg';

/**
 * Renders the Avatars component.
 * @returns {JSX.Element} The rendered Avatars component.
 */
function Avatars() {
  return (
    <div className="avatars">
      <img src={Avatar} alt="Avatar A" />
      <img src={Line} alt="Line" />
      <img src={Avatar} alt="Avatar B" />
    </div>
  );
}

export default Avatars;
