/**
 * Converts a string to title case.
 * @param {string} text - The string to be converted.
 * @returns {string} - The converted title case string.
 */
export default function handleTitleCase(text) {
  return text
    ? text
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '';
}

/**
 * Converts a string to upper case.
 * @param {string} text - The string to be converted.
 * @returns {string} - The converted upper case string.
 */
export function handleUpperCase(text) {
  return text ? text.toUpperCase() : '';
}

/**
 * Converts a value to a string.
 * @param {*} value - The value to be converted.
 * @returns {string} - The converted string.
 */
export function handleToString(value) {
  return value ? value.toString() : '';
}

/**
 * Generates a unique key based on the provided prefix and the current timestamp.
 * @param {string} pre - The prefix for the key.
 * @returns {string} The generated unique key.
 */
export const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};
