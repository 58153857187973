/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import '@fontsource/open-sans';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/700-italic.css';
import '@fontsource/open-sans/800-italic.css';
import '@fontsource/open-sans/300-italic.css';
import '@fontsource/open-sans/600-italic.css';
import '@fontsource/open-sans/500-italic.css';
import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SignIn from './component/SignIn';
import Home from './component/Home';
import Landing from './Landing';
import PageWithNavbar from './PageWithNavbar';
import EmailPreferences from './component/EmailPreferences';
import Account from './component/Account';
import Paywall   from './component/pricing/Paywall';
import Pricing   from './component/pricing/Pricing';
import Feedback from './component/Feedback';
import FeedbackConfirm from './component/FeedbackConfirm';
import PrivacyPolicy from './component/PrivacyPolicy';
import TermsOfService from './component/TermsOfService';
import DefaultIntroduction from './component/DefaultIntroduction';
import Introduction from './component/Introduction';
import context from './exports.json';
import AuthService from './service/AuthService';

/**
 * The main application used for the Looop App.
 */
/* eslint-disable */
const App = () => {
  const [loaded, setLoaded] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const authService = new AuthService(context.ApiGatewayUrl);

  useEffect(() => {
    if (!loaded) {
      const token = authService.getToken();
      if (token) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoaded(true);
    }
  }, [loaded]);

  const setTokens = (tokens) => {
    if (!tokens) {
      return;
    }
    setUserInfo(tokens);
  };

  if (!loaded) {
    return <></>;
  }

  return (
    <>
      <GoogleOAuthProvider
        clientId="78095042376-bi42er62kuaim7ftb87bd1i8c6ifp9rj.apps.googleusercontent.com"
        redirectUri='http://localhost:3000"'
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/unsubscribe" element={<EmailPreferences />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/feedbackConfirm" element={<FeedbackConfirm />} />

            <Route path="app" element={<PageWithNavbar />}>
              <Route path="paywall" element={<Paywall/>} />
              <Route path="pricing" element={<Pricing/>} />
              <Route path="home" element={<Home/>} />
              <Route path="introduction" element={<Introduction />} />
              <Route path="account" element={<Account />} />
              <Route path="intro/default" element={<DefaultIntroduction />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
