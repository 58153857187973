import { EmailTemplate } from "common";
import { EmailTemplateVersion } from "common";
import { EditContact } from "common";
import { CreateContact } from "common";
import axios, { AxiosError, AxiosInstance } from 'axios';

export default class SubscriptionService {
  apiUrl: string;
  axiosInstance: AxiosInstance;

  constructor(
    apiUrl: string,
    applyInterceptors: (axiosInstance: AxiosInstance) => void
  ) {
    this.apiUrl = apiUrl;

    // Create an Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.apiUrl,
    });

    // Apply interceptors
    applyInterceptors(this.axiosInstance);
  }

  getToken = () => {
    return JSON.parse(localStorage.getItem('tokens') as string).id_token;
  };

  product = async (priceId: string): Promise<any> => {
    const response = await this.axiosInstance.get(
      `checkout/${priceId}`,
    
      {
        headers: {
          Authorization: this.getToken(),
        },
      }
    );
    window.location.href = response.data;
  };

  settings = async (): Promise<any> => {
    const response = await this.axiosInstance.get(
      `billing-portal`,
      {
        headers: {
          Authorization: this.getToken(),
        },
      }
    );
    window.location.href = response.data;
  };
}
