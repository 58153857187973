import { EmailTemplate } from "common";
import { EmailTemplateVersion } from "common";
import { EditContact } from "common";
import { CreateContact } from "common";
import axios, { AxiosError, AxiosInstance } from 'axios';

export default class TemplateService {
  apiUrl: string;
  axiosInstance: AxiosInstance;

  constructor(
    apiUrl: string,
    applyInterceptors: (axiosInstance: AxiosInstance) => void
  ) {
    this.apiUrl = apiUrl;

    // Create an Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.apiUrl,
    });

    // Apply interceptors
    applyInterceptors(this.axiosInstance);
  }

  getToken = () => {
    return JSON.parse(localStorage.getItem('tokens') as string).id_token;
  };

  get = async (id: string): Promise<any> => {
    try {
      const response = await this.axiosInstance.get(`api/template/${id}`, {
        headers: {
          Authorization: this.getToken(),
        },
      });
      return response.data;
    } catch (axiosError) {

      if ((axiosError as any).response?.status === 404) {
        return;
      }

      throw axiosError;
    }
  };

  list = async (): Promise<any> => {
    try {
      const response = await this.axiosInstance.get('api/template', {
        headers: {
          Authorization: this.getToken(),
        },
      });
      return response.data;
    } catch (axiosError) {

      if ((axiosError as any).response?.status === 404) {
        return;
      }

      throw axiosError;
    }
  };

  create = async (name: string): Promise<any> => {
    const response = await this.axiosInstance.post(
      `api/template`,
      {
        Name: name,
      },
      {
        headers: {
          Authorization: this.getToken(),
        },
      }
    );
    return response.data;
  };

  saveVersion = async (templateId, request: EditContact): Promise<any> => {
    const response = await this.axiosInstance.post(
      `api/template/${templateId}/version`,
      request,
      {
        headers: {
          Authorization: this.getToken(),
        },
      }
    );
    return response.data;
  };
}
