/* eslint-disable prettier/prettier */

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import PropTypes from 'prop-types';

/**
 * Renders the HomeSearch component.
 *
 * @returns {JSX.Element} The rendered HomeSearch component.
 */
function HomeSearch({setSearchBy}) {

    const [inputValue, setInputValue] = useState('');
    /**
     * Handles the form submission.
     *
     * @param {Event} e - The form submission event.
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const text = toLowerCase(e.target[0].value);
        setInputValue(text);
        setSearchBy(text);
    };

    /**
     * Handles the key down event.
     *
     * @param {Event} e - The key down event.
     */

    /**
     * Handles the input change event.
     *
     * @param {Event} e - The input change event.
     */
    const handleInputChange = (e) => {
        const text = toLowerCase(e.target.value);
        setInputValue(text);
        if(!text) {
            setSearchBy('');
        }
    }

    /**
     * Converts the input text to lowercase.
     *
     * @param {string} text - The text to be converted to lowercase.
     * @returns {string} The lowercase version of the input text.
     */
    const toLowerCase = (text) => {
        if(typeof text === 'string' && text.length > 0) {
            return text.toLowerCase();
        }
        return text;
    }


    return (
        <form className="home-table__card__search" onSubmit={handleSubmit}>
            <MagnifyingGlassIcon className="home-table__card__search__icon" />
            <input
                onChange={handleInputChange}
                type="text"
                name='search'
                placeholder="Search"
                className="home-table__card__search__input"
                value={inputValue}
            />
            <button
                // disabled={!inputValue || inputValue.length <= 1}
                type="submit"
                className="home-table__card__search__button"
            >
                Search
            </button>
        </form>
    );
}

HomeSearch.propTypes = {
    setSearchBy: PropTypes.func.isRequired,
};

export default HomeSearch;