import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';

/**
 * Tos Component
 * @returns ToS Component
 */
export default function TermsOfService() {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Terms of Service for Looop</h1>
      <p className="text-sm text-gray-500 mb-6">Last Updated: 2024-11-01</p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        1. Introduction and Acceptance of Terms
      </h2>
      <p className="mb-4">
        By using Looop, an introduction management software that integrates with
        Gmail to facilitate introductions on behalf of users, you agree to these
        Terms of Service. If you do not agree with these terms, you may not use
        our service.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">2. Eligibility</h2>
      <p className="mb-4">
        Looop is intended for use by professionals, businesses, and individuals
        aged 18 or older. Users are responsible for ensuring that their usage of
        Looop complies with all applicable laws and regulations.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        3. Account and Access Requirements
      </h2>
      <p className="mb-4">
        Users log in to Looop through their Google Accounts, allowing Looop
        access to send emails on their behalf. Users are responsible for
        maintaining the security of their Google Account credentials and should
        not share or expose these credentials. For added security, we recommend
        enabling two-factor authentication on your Google Account.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        4. Permissions and Access
      </h2>
      <p className="mb-4">
        By connecting your Google Account to Looop, you grant us permission to:
      </p>
      <ul className="list-disc list-inside ml-4 mb-4">
        <li>Send emails from your Gmail account.</li>
        <li>
          Access your personal and publicly available information on Google.
        </li>
        <li>View your primary Google Account email address.</li>
      </ul>
      <p className="mb-4 italic">
        Note: These permissions are necessary for Looop to function effectively
        as an introduction management tool. For more details on how we handle
        your data, please refer to our{' '}
        <a href="#" className="text-blue-500 underline">
          Privacy Policy
        </a>
        .
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">5. Payment Terms</h2>
      <p className="mb-4">
        <strong>Subscription Plans:</strong> Looop offers a free tier and a
        premium plan at $11 per month.
      </p>
      <p className="mb-4">
        <strong>Free Trial:</strong> New users are eligible for a one-month free
        trial of the premium plan.
      </p>
      <p className="mb-4">
        <strong>Renewals and Cancellations:</strong> Subscriptions are
        automatically renewed monthly. Users may cancel their subscriptions at
        any time, but refunds will not be provided for partial months or unused
        time within the current billing cycle.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">6. Acceptable Use</h2>
      <p className="mb-4">
        Users must not use Looop to send spam or unsolicited emails. Misuse of
        Looop to engage in spammy or illegal activities is strictly prohibited
        and may result in account suspension or termination. Users are
        responsible for ensuring that the content of their introductions
        complies with all applicable laws and does not violate the rights of
        others.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        7. User Content Ownership
      </h2>
      <p className="mb-4">
        Any custom content or data users input into Looop, including
        personalized messages or introduction templates, remains the property of
        the user.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        8. Privacy and Data Security
      </h2>
      <p className="mb-4">
        <strong>Data Collection:</strong> Looop collects emails, contact
        details, and information directly relevant to user introductions. This
        data is processed only for the purpose of facilitating introductions.
      </p>
      <p className="mb-4">
        <strong>Data Security:</strong> All data is encrypted, and access is
        restricted to authorized personnel. We take reasonable measures to
        protect your information but cannot guarantee absolute security.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        9. Disclaimers and Limitation of Liability
      </h2>
      <p className="mb-4">
        Looop does not guarantee 24/7 availability or uninterrupted service.
        Users acknowledge that there may be occasional downtimes.
      </p>
      <p className="mb-4">
        <strong>No Liability:</strong> To the fullest extent permitted by law,
        Looop disclaims any liability for damages arising from the use or
        inability to use our app, including errors, omissions, or data loss.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        10. Modifications to Terms
      </h2>
      <p className="mb-4">
        Looop reserves the right to update these Terms of Service on a quarterly
        basis or as necessary. Users will be notified via email about any
        significant changes to the terms.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        11. Governing Law and Dispute Resolution
      </h2>
      <p className="mb-4">
        These terms are governed by the laws of the state of Florida, USA. Any
        disputes arising from the use of Looop will be resolved in the courts of
        Florida.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        12. Termination of Use
      </h2>
      <p className="mb-4">
        Looop reserves the right to suspend or terminate user access for
        violation of these terms, especially in cases of misuse, such as
        spamming or illegal activities.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        13. Contact Information
      </h2>
      <p className="mb-4">
        For questions regarding these terms, please contact us at{' '}
        <a href="mailto:info@wellaware.me" className="text-blue-500 underline">
          info@wellaware.me
        </a>
        .
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        14. Compromised Account Policy
      </h2>
      <p className="mb-4">
        <strong>User Responsibility:</strong> If users suspect that their
        account credentials have been compromised, they must notify us
        immediately at{' '}
        <a href="mailto:info@wellaware.me" className="text-blue-500 underline">
          info@wellaware.me
        </a>
        . Looop is not responsible for unauthorized access resulting from
        compromised credentials.
      </p>
      <p className="mb-4">
        <strong>Account Suspension and Restoration:</strong> Upon receiving
        notice of a possible compromise, we may temporarily suspend the account
        to prevent unauthorized access. Users will need to verify their identity
        and potentially reset their credentials before account access is
        restored.
      </p>
      <p className="mb-4">
        <strong>Data Integrity:</strong> Looop will make reasonable efforts to
        maintain data integrity, but we cannot guarantee protection against data
        loss or misuse resulting from compromised credentials.
      </p>

      <div>
        <a
          href="/"
          type="button"
          className="bg-blue-700 text-white hover:bg-blue-500 border font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
        >
          <ChevronLeftIcon className="w-5 h-4" />
          Go Back
        </a>
      </div>
    </div>
  );
}
