import React, { useState } from 'react';
import propTypes from 'prop-types';
import {
  ChatBubbleBottomCenterIcon,
  UserPlusIcon,
} from '@heroicons/react/24/solid';
import { Dialog } from '@headlessui/react';
import ContactModal from './ContactModal';
import ContactCard from './ContactCard';
import SelectContacts from './SelectContacts';
import SearchContacts from './SearchContacts';
import HelpBanner from './HelpBanner';
/**
 * Used when user wants to crete Intro
 */
export default function SelectContactsPane({
  title,
  open,
  contacts,
  onNewContactSubmit,
  onFinishSelection,
  showHelp,
}) {
  // Be able to render a Create Contact Modal
  const [addNewContactVisible, setNewContactVisibility] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState({});
  const [searchFilter, setSearchFilter] = useState('');

  /**
   * Capture the event and use the callback, also
   * close the modal.
   */
  const submitContact = (first, last, email) => {
    onNewContactSubmit(first, last, email);
    setNewContactVisibility(false);
  };

  /**
   * Contact Remove Cb
   * @param {string} contact - The contact to be removed.
   */
  const onContactRemove = (contact) => {
    const newContacts = { ...selectedContacts };
    if (newContacts.first === contact) delete newContacts.first;
    else if (newContacts.second === contact) delete newContacts.second;
    setSelectedContacts(newContacts);
  };

  /**
   * Contact Select Callback
   * @param {string} contact - The selected contact.
   */
  const onContactSelect = (contact) => {
    if (selectedContacts.first === undefined) selectedContacts.first = contact;
    else if (selectedContacts.second === undefined)
      selectedContacts.second = contact;
    // Automatic selection of contacts
    // if (selectedContacts.first && selectedContacts.second)
    //   onFinishSelection(selectedContacts.first, selectedContacts.second);
    setSelectedContacts({ ...selectedContacts });

    if (selectedContacts.first && selectedContacts.second) {
      onFinishSelection(selectedContacts.first, selectedContacts.second);
    }
    // TODO: Fix this, or remove it// TODO: Fix this, or remove it
  };

  /**
   * Finish Selection Callback
   */
  const handleValidateFinishSelection = () => {
    const { first, second } = selectedContacts;
    if (first && second) {
      onFinishSelection(first, second);
    }
  };

  /**
   * Check to see if full
   */
  const isFull = () => {
    return (
      selectedContacts.first !== undefined &&
      selectedContacts.second !== undefined
    );
  };

  return (
    <div className="sm:items-start">
      {open && (
        <div className="text-center sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold text-gray-900 leading-6 border-b-2 border-gray-200 pb-3"
          >
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <div className="mt-4 border-t-0 border-gray-200 rounded" />
            <ContactModal
              onClose={() => setNewContactVisibility(false)}
              onSubmit={submitContact}
              isOpen={addNewContactVisible}
            />
            <SelectContacts
              selectedContacts={selectedContacts}
              onContactRemove={onContactRemove}
            />

            {showHelp && (
              <HelpBanner
                className={'mt-4'}
                text={`Don't worry about the order--both contacts will be equally introduced.`}
              />
            )}
            <div className="mt-4 mb-2 border border-t-0 border-gray-200 rounded" />
            <h4 className="text-md my-2">Contacts</h4>

            <div className="grid grid-cols-[70%,30%] space-x-2">
              <div>
                <SearchContacts
                  onSearchChange={(text) => setSearchFilter(text)}
                />
              </div>
              <button
                type="button"
                disabled={showHelp}
                className="border-dashed border-emerald-700 text-emerald-700 border rounded-lg items-center flex h-16 justify-center hover:bg-emerald-800 hover:text-white"
                onClick={() => setNewContactVisibility(true)}
              >
                <UserPlusIcon className="w-5 h-5 mr-2" />
                Add New Contact
              </button>
            </div>

            {showHelp && (
              <HelpBanner
                className={'mt-4'}
                text={`For this tutorial, we've provided default contacts for you to introduce.`}
              />
            )}

            <div className="block mt-2 space-y-4">
              {contacts
                .filter(
                  (c) =>
                    c !== selectedContacts.first &&
                    c !== selectedContacts.second
                )
                .filter(
                  (f) =>
                    f.firstName
                      .toLowerCase()
                      .indexOf(searchFilter.toLowerCase()) > -1 ||
                    f.lastName
                      .toLowerCase()
                      .indexOf(searchFilter.toLowerCase()) > -1 ||
                    f.email.toLowerCase().indexOf(searchFilter.toLowerCase()) >
                      -1
                )
                .map((c, index) => (
                  <ContactCard
                    key={`${c.firstName}${c.lastName + index}`}
                    contact={c}
                    disabled={isFull(selectedContacts)}
                    onSelect={onContactSelect}
                    position={index + 1}
                  />
                ))}
            </div>
          </div>

          {isFull() && (
            <button
              type="button"
              onClick={handleValidateFinishSelection}
              className="button__primary mt-4"
            >
              <ChatBubbleBottomCenterIcon className="flex w-5 h-5 mr-2" />
              Create the Intro
            </button>
          )}
        </div>
      )}
    </div>
  );
}

SelectContactsPane.propTypes = {
  title: propTypes.string.isRequired,
  open: propTypes.bool.isRequired,
  showHelp: propTypes.bool,
  onNewContactSubmit: propTypes.func.isRequired,
  onFinishSelection: propTypes.func.isRequired,
  contacts: propTypes.arrayOf(propTypes.shape({})).isRequired,
};
