import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Icon from '../Icon';
import Footer from '../Footer';
import { ClockIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';
import { ANNUAL, MONTHLY } from './PricingCard';
import FAQS from '../FAQs';

export default function Pricing() {
    const navigate = useNavigate();
  return (
    <>
      <div className="bg-gradient-to-tl from-75% to-emerald-50 from-white">
        <div className="sm:w-1/2 w-full mx-auto pt-10">
          <h1 className="text-center text-md pt-5">
            PRICING
          </h1>
          <h1 className="text-center sm:text-2xl text-xl pt-5 font-semibold">
            Simple Pricing.<br />
            Full Access to Features.
          </h1>
          <p className="text-center py-5 mx-10 ">
            Early access members get everything in one plan--monthly or annual, your call.
          </p>
        </div>
        </div>
        <div className="bg-white">
        <div className="p-5 space-y-8 sm:space-y-0 sm:p-0 w-full sm:w-5/12  mx-auto pt-6 sm:grid sm:grid-cols-2 sm:space-x-4 ">
          <div className='w-full'>
          { MONTHLY}
          </div>
          <div className='w-full'>
          { ANNUAL }
          </div>
        </div>
        <FAQS />
      </div>
      <Footer/>
    </>
  );
}
