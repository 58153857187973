import React from 'react';
import propTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';

/**
 * Side By Side Editor
 * @param {import('@headlessui/react/dist/types').Props} props used in the Editor
 * @returns A Component for a Side By Side Editor
 */
export default function SideBySideEditor({
  setEditorState,
  onChangeSubject,
  subjectText,
  editorState,
  context,
}) {
  return (
    <div className="container">
      <div className="grid grid-cols-2">
        <div className="col-span-2">
          <div className="text-xs text-gray-500">
            <div className="mb-2">
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Subject
              </label>
              <input
                onChange={onChangeSubject}
                value={subjectText}
                type="text"
                placeholder="Enter a Subject Line Here"
                className="w-full border border-gray-300 px-4 py-2 rounded-md text-gray-700 focus:outline-none"
              />
            </div>
            <span className="block text-sm font-medium text-gray-900 mt-4 mb-3">
              Body
            </span>
            <div className="border border-gray-300 px-4 py-2 rounded-md">
              <Editor
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            </div>
          </div>
        </div>
        {/* <div className="col-span-1">
          <div className="p-2 text-xs text-gray-500">
            <div className="mt-4 mb-2 border border-t-0 border-gray-200 rounded" />

            <div className="mt-4 mb-2 border border-t-0 border-gray-200 rounded" />
            <div className="flex">
              Subject:
              <p className="pl-2 font-semibold">
                {Handlebars.compile(subjectText)(context)}
              </p>
            </div>
            <div className="mt-2 mb-2 border border-t-0 border-gray-200 rounded" />

            <div className="mt-6 border border-gray-200 rounded-lg">
              <p>
                {convertToRaw(editorState.getCurrentContent()).blocks.map(
                  (b) => (
                    <p>
                      {Handlebars.compile(b.text)(context)}
                      <br />
                    </p>
                  )
                )}
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

SideBySideEditor.propTypes = {
  setEditorState: propTypes.func.isRequired,
  onChangeSubject: propTypes.func.isRequired,
  subjectText: propTypes.string.isRequired,
  editorState: propTypes.object.isRequired,
  context: propTypes.object.isRequired,
};
