import React, { useEffect } from 'react';
import Footer from './Footer';
import AccordionAccount from './AccordionAccount';
import BackButton from './BackButton';
import DefaultIntroduction from './DefaultIntroduction';
import Alert from './Alert';
import context from '../exports.json';
import AuthService from '../service/AuthService';
import { useNavigate } from 'react-router';
import { CreditCardIcon } from '@heroicons/react/24/solid';
import { addAuthInterceptor } from '../service/AuthInterceptor';
import SubscriptionService from '../service/SubscriptionService';

/**
 * Main Web Page used for the Account
 */
export default function Account() {
  const authService = new AuthService(context.ApiGatewayUrl);
  const subscriptionService = new SubscriptionService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );


  const userInfo = authService.getUserInfo();
  const navigate = useNavigate();

  const logOut = async () => {
    authService.logout();
    navigate('/');
  };

  const TABS = {
    'Connected Account': {
      content: (
        <div>
          Google connection options go here.
          <br />
          TBD
          <br />
          Find out more information by
          <span className="text-blue-600 cursor-pointer">
            {' '}
            reading the license
          </span>
        </div>
      ),
    },
    'Feedback Settings': {
      link: (
        <a className="text-blue-600" href="/app/intro/default">
          Default Introduction Template
        </a>
      ),
      content: (
        <div className="mx-9">
          <Alert text="Be careful with the dynamic data" />
          <DefaultIntroduction />
        </div>
      ),
    },

    'Plans & Billing': {
      content: (
        <div className="mx-9">
          <button
            type="button"
            onClick={ () => subscriptionService.settings()}
            className="bg-gradient-to-tl from-blue-700 to-blue-500 text-white rounded-lg px-4 py-2 flex items-center hover:bg-blue-700"
          >
            <CreditCardIcon className="flex w-5 h-5 mr-2" />
            Manage your Billing
          </button>
        </div>
      ),
    },
    // 'Setting X': {
    //   content: <>--</>,
    // },
    // 'Delete Account': {
    //   content: <>--</>,
    // },
  };

  return (
    <>
      <div className="account">
        <BackButton path="/app/home" page="Home" />
        <div className="account__intro">
          <h2 className="account__intro__title">Account</h2>
          <h3 className="account__intro__description">
            Here you can manage your account and settings.
          </h3>
        </div>
        <div className="account__profile">
          <img
            className="account__profile__photo"
            src={userInfo.picture}
            alt="profile"
          />
          <p className="account__profile__email">{`${userInfo.email}`}</p>
        </div>
        <div className="container mx-auto mt-12">
          {/* <IntroStats centerTitle /> */}
        </div>
        <div className="container lg:w-3/4 sm:w-4/4 md:w-4/4 pt-8 mx-auto rounded-t-lg">
          {Object.keys(TABS).map((key, index) => (
            <AccordionAccount
              title={key}
              first={index === 0}
              key={Math.floor(Math.random() * 1000)}
            >
              {TABS[key].content}
            </AccordionAccount>
          ))}
        </div>
        <div className="mt-6 mb-28">
          <button
            className="container flex justify-center w-3/4 h-8 p-1 mx-auto text-black bg-gray-300 hover:bg-blue-700 hover:text-white rounded-md"
            type="button"
            onClick={logOut}
          >
            Logout
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
