import React, { useEffect, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Logo from '../icon/Logo.png';
import Icon from './Icon';
import BlacklistService from '../service/BlacklistService';
import context from '../exports.json';

interface BlacklistEntry {
  contactId: string;
  facilitator?: string | null;
  isGlobal?: boolean;
}

/**
 * Email Preferences component that manages email preference settings.
 */
export default function EmailPreferences() {
  const [searchParams] = useSearchParams();
  const contactId = searchParams.get('contact') as string;
  const facilitator = searchParams.get('facilitator') as string;

  const blacklistService = new BlacklistService(context.ApiGatewayUrl);

  const [loaded, setLoaded] = useState(false);
  const [blacklistEntry, setBlacklistEntry] = useState<BlacklistEntry>({
    contactId: contactId,
    facilitator: undefined,
    isGlobal: undefined,
  });
  const [showModal, setShowModal] = useState(false);

  // Fetch blacklist entry when component loads
  useEffect(() => {
    if (!loaded && contactId) {
      blacklistService
        .get(contactId, facilitator)
        .then((response) => {debugger;setBlacklistEntry(response)})
        .catch((err) => {
          setBlacklistEntry( {...blacklistEntry, facilitator: undefined})
          console.error(err)
        })
        .finally(() => setLoaded(true));
    }
  }, [contactId, facilitator, loaded]);

  /**
   * Submit email preferences
   */
  const submitEmailPreferences = async () => {
    try {

      debugger
      if (!blacklistEntry.facilitator && !blacklistEntry.isGlobal) {
        await blacklistService.delete(contactId, facilitator);
        
      } else {

      await blacklistService.save(
        blacklistEntry.contactId,
        blacklistEntry.facilitator as string,
        blacklistEntry.isGlobal
      );
    }
    setShowModal(true);
    } catch (error) {
      // Handle errors if needed
    }
  };

  if (!contactId || !facilitator) {
    return <Navigate replace to="/home" />;
  }

  return (
    <div>
      <Modal show={showModal} onClose={() => setShowModal(false)} />
      <div className="container content-center w-32 mx-auto">
        <img src={Logo} className="w-full pt-8" alt="Loop Icon" />
      </div>
      <div className="container w-full mx-0 mt-6 bg-white border border-gray-150 rounded-xl sm:w-4/5 md:w-3/5 lg:w-2/5 sm:mx-auto p-4">
        <div className="font-medium">
          <h2 className="text-lg font-semibold sm:text-xl">Email Preferences</h2>
          <p className="mt-2 text-sm sm:text-base">These are our communications with you.</p>
          <div className="mt-4 border border-t-0 border-gray-200" />
          <div className="mt-4 space-y-4">
            <p className="font-semibold text-sm sm:text-base">
              Choose which Looop emails you would like to unsubscribe from.
            </p>

            {/* Checkbox for Facilitator */}
            <EmailCheckboxes
              label={`Introduction Emails from ${facilitator}`}
              checked={blacklistEntry.facilitator != undefined}
              onChange={() =>
                setBlacklistEntry((prev) => ({
                  ...prev,
                  facilitator: !prev.facilitator ? facilitator : undefined,
                }))
              }
            />

            {/* Checkbox for Global */}
            <EmailCheckboxes
              label="Introduction Emails from users of Looop"
              checked={blacklistEntry.isGlobal || false}
              onChange={() =>
                setBlacklistEntry((prev) => ({
                  ...prev,
                  isGlobal: !prev.isGlobal,
                }))
              }
            />

            <button
              type="button"
              onClick={submitEmailPreferences}
              className="w-full px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 sm:w-auto"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Modal component to show a success message
 */
const Modal: React.FC<{ show: boolean; onClose: () => void }> = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex md:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                  <Icon icon={<CheckCircleIcon />} iconClass="w-10 h-10 text-green-500" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold text-gray-900" id="modal-title">
                    Updated Email Preferences
                  </h3>
                  <p className="text-md text-gray-500">Please wait up to 24 hours to see this change affect.</p>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6">
              <button
                onClick={onClose}
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Checkbox component to handle email preferences for each checkbox option
 */
const EmailCheckboxes: React.FC<{
  label: string;
  checked: boolean;
  onChange: () => void;
}> = ({ label, checked, onChange }) => (
  <div className="flex items-center text-sm sm:text-base">
    <input
      checked={checked}
      onChange={onChange}
      id="facilitator-checkbox"
      type="checkbox"
      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
    />
    <label htmlFor="facilitator-checkbox" className="flex ml-2 text-gray-900">
      <p>{label}</p>
    </label>
  </div>
);
