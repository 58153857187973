import Joyride, { TooltipRenderProps } from 'react-joyride';

export default function Tooltip(props: TooltipRenderProps) {
  const {
    backProps,
    closeProps,
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
  } = props;

  return (
    <div className={`tooltip__body bg-black bg-opacity-70 text-white p-6 rounded-lg ${step.data.className}`}>

      {step.title && (
        <h4 className="tooltip__title text-xl font-semibold">{step.title}</h4>
      )}

      <div className="tooltip__content mt-4">{step.content}</div>

      <div className="tooltip__footer mt-6 flex justify-between items-center">
        <div className="text-sm">
          Step {index + 1} of {step.data.steps}
        </div>

        <div className="flex space-x-2">
          <button
            className="tooltip__close bg-transparent text-white  px-4 py-2 rounded"
            {...skipProps}
          >
            Skip Tour
          </button>

          <button
            className="tooltip__next bg-emerald-600 text-white px-4 py-2 rounded"
            {...primaryProps}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
