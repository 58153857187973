import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Handlebars from 'handlebars';
import { ContentState, EditorState } from 'draft-js';
import context from '../exports.json';
import TemplateService from '../service/TemplateService';
import AuthService from '../service/AuthService';
import { addAuthInterceptor } from '../service/AuthInterceptor';
import { convertToRaw } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';

import {
  ArrowUturnLeftIcon,
  ArrowLongLeftIcon,
} from '@heroicons/react/24/solid';

import Toast from './Toast';

import TEMPLATE_BODY from '../resources/DefaultEmailBody.txt';
import TEMPLATE_SUBJECT from '../resources/DefaultEmailSubject.txt';
import SideBySideEditor from './SideBySideEditor';

/**
 * Default Introduction
 */
export default function DefaultIntroduction() {
  /**
   * How long a toast should display for
   */
  const TOAST_TTL = 5000;

  const [subjectText, setSubjectText] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [templateId, setTemplateId] = useState();
  const [resetSubject, setResetSubject] = useState();
  const [resetBody, setResetBody] = useState();

  /**
   * Contact to string for text
   */
  const toString = (contact) => {
    if (!contact) return '';

    const firstName = contact.firstName ?? '';
    const lastName = contact.lastName ?? '';

    return `${firstName} ${lastName}`;
  };

  /**
   * Using the Rendered Template Message, lets prepop
   * the text.
   */
  Handlebars.registerHelper('toString', (e) => toString(e));

  /**
   * Lazily Set the Editor State.
   */
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText(''))
  );

  const authService = new AuthService(context.ApiGatewayUrl);
  const templateService = new TemplateService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );
  const handleTemplateCalls = async () => {
    const response = await templateService.list();

    let subjectText;
    let bodyText;
    let templateId;

    if (!response.length) {
      // Fetch data when no response is available
      const subjectResponse = await fetch(TEMPLATE_SUBJECT);
      subjectText = await subjectResponse.text();

      const bodyResponse = await fetch(TEMPLATE_BODY);
      bodyText = await bodyResponse.text();
    } else {
      // Use data from the response when available
      subjectText = response[0].subject;
      bodyText = response[0].body;
      templateId = response[0].id;
    }

    setSubjectText(subjectText);
    setEditorState(
      EditorState.createWithContent(ContentState.createFromText(bodyText))
    );

    // Allow us to reset to this.
    setResetBody(bodyText);
    setResetSubject(subjectText);

    if (templateId) {
      setTemplateId(templateId);
    }
  };

  useEffect(() => {
    if (loaded) {
      return;
    }

    handleTemplateCalls();

    setLoaded(true);
  }, [loaded]);

  const onSubmit = async () => {
    const asText = convertToRaw(editorState.getCurrentContent())
      .blocks.map((b) => b.text)
      .join('\n');

    if (!templateId) {
      const response = await templateService.create('default');
      await templateService.saveVersion(response.id, {
        Body: asText,
        Subject: subjectText,
      });

      setTemplateId(response.id);
    } else {
      await templateService.saveVersion(templateId, {
        Body: asText,
        Subject: subjectText,
      });
    }

    // Any time we reset...
    setResetSubject(subjectText);
    setResetBody(asText);
  };

  const onRefresh = () => {
    setSubjectText(resetSubject);
    setEditorState(
      EditorState.createWithContent(ContentState.createFromText(resetBody))
    );
  };
  /**
   *
   * @param {*} e
   */
  const onChangeSubject = (e) => {
    setSubjectText(e.target.value);
  };
  /**
   * Create a new Toast
   */
  const addNewToast = (toast) => {
    const newToasts = toasts;
    newToasts.push(toast);
    setToasts([...newToasts]);

    // Kill the Toast in 5 seconds
    setTimeout(() => removeToast(toast), TOAST_TTL);
  };

  /**
   * Remove Toast
   */
  const removeToast = (toast) => {
    const newToasts = toasts;
    const indexToRemove = newToasts.indexOf(toast);
    newToasts.splice(indexToRemove, 1);

    setToasts([...newToasts]);
  };

  const handlebarsContext = {
    facilitator: {
      firstName: 'George',
      lastName: 'Castanza',
    },
    contacts: [
      {
        firstName: 'John',
        lastName: 'Doe',
      },
      {
        firstName: 'Bob',
        lastName: 'Lablaw',
      },
    ],
  };

  return (
    <div className="container mx-auto">
      {loaded && (
        <>
          <div className="mt-4 mb-2 border border-t-0 border-gray-200 rounded" />
          <h2>Edit Introduction Message </h2>
          <SideBySideEditor
            setEditorState={setEditorState}
            onChangeSubject={onChangeSubject}
            subjectText={subjectText}
            editorState={editorState}
            context={handlebarsContext}
          />
          <div className="grid grid-cols-1">
            <div className="cols-span-4">
              <div className="float-left">
                <a
                  href="/app/home"
                  type="button"
                  className="flex px-4 py-4 mb-2 text-xs font-semibold text-blue-700 bg-transparent rounded hover:bg-blue-500 hover:text-white hover:border-transparent"
                >
                  <ArrowLongLeftIcon className="w-4 h-4 mr-2" />
                  Return to Home Page
                </a>
              </div>
            </div>

            <div className="cols-span-4 mt-9">
              <div className="float-right mt-2">
                <button
                  onClick={onSubmit}
                  type="button"
                  className="px-4 py-2 mb-2 font-bold text-white bg-blue-500 border border-blue-700 rounded hover:bg-blue-700"
                >
                  Save Introduction Template
                </button>
              </div>
              <div className="float-left">
                <button
                  onClick={onRefresh}
                  type="button"
                  className="flex px-4 py-4 mb-2 text-xs font-semibold text-gray-700 bg-transparent border border-gray-200 rounded hover:bg-blue-500 hover:text-white hover:border-transparent"
                >
                  <ArrowUturnLeftIcon className="w-4 h-4 mr-2" />
                  Reset Template Message
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="container fixed bottom-0 w-screen p-4 mx-auto space-y-2 grid place-items-center">
        {toasts.map((toast, index) => {
          return (
            <Toast
              key={`${toast}toast-${index}`}
              text={toast.text}
              cta={toast.cta}
              onClick={toast.onClick}
              onClose={removeToast}
            />
          );
        })}
      </div>
    </div>
  );
}
