import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

/**
 * CreateIntroResponseModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {Function} props.onSubmit - The submit callback function.
 * @param {Function} props.onClose - The close callback function.
 * @param {Object} props.intro - The intro object.
 * @param {boolean} props.error - The error object.
 * @returns {JSX.Element} The CreateIntroResponseModal component.
 */
function CreateIntroResponseModal({
  isOpen,
  onSubmit,
  onClose,
  intro,
  error = false,
}) {
  const { firstName, lastName, email } = intro;
  const titleError = 'Error, the intro could not be created.';
  const titleSuccess = 'You have created a new intro!';
  const subtitleError = 'Please try again.';
  const subtitleSuccess = `${firstName || ''} ${lastName || ''} `;
  const descriptionError = 'There was an error creating the intro.';
  const descriptionSuccess = 'You can see it in the intros table.';

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="add-contact-response">
        <h1 className="add-contact-response__title">
          {error ? titleError : titleSuccess}
        </h1>
        <h4 className="add-contact-response__subtitle">
          {error ? subtitleError : subtitleSuccess}
        </h4>
        <h4 className="add-contact-response__subtitle">{error ? '' : email}</h4>
        <div className="add-contact-response__description">
          <div className="mt-1 mb-5 border border-t-1 border-gray-200 rounded" />
          <h2 className="add-contact-response__description__text">
            {error ? descriptionError : descriptionSuccess}
          </h2>
        </div>
        <button type="button" className="button__primary" onClick={onClose}>
          Ok
        </button>
      </div>
    </Modal>
  );
}

CreateIntroResponseModal.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
};

CreateIntroResponseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  intro: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
};

export default CreateIntroResponseModal;
