import React from 'react';
import PropTypes from 'prop-types';
import { handleUpperCase } from '../helpers/helpers';
import Dots from '../icon/home/dots-horizontal.svg';
/**
 * Contact Card
 */
export default function ContactCard({ contact, onSelect, disabled, position }) {
  return (
    <div className="contact-card">
      <div className="contact-card__info">
        <div
          className={`contact-card__info__circle-profile ${
            position == 1 ? 'bg-green' : 'bg-purple'
          }`}
        >
          {handleUpperCase(
            `${contact.firstName[0] || ''}${contact?.lastName?.[0] || ''}`
          )}
        </div>
        <div className="contact-card__info__text">
          <h4 className="contact-card__info__text__name">
            {`${contact.firstName} ${contact.lastName}`}
          </h4>
          <p className="contact-card__info__text__email">{contact.email}</p>
        </div>
      </div>
      <div className="contact-card__info__action">
        <button
          type="button"
          disabled={disabled}
          onClick={() => onSelect(contact)}
          className={`px-4 py-2 bg-transparent font-semibold border border-gray-200 rounded hover:bg-blue-500 ${
            disabled ? '' : ' hover:text-white hover:border-transparent'
          }`}
        >
          Select
        </button>
      </div>
    </div>
  );
}

ContactCard.propTypes = {
  contact: PropTypes.objectOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
};
