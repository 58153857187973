import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Icon from '../Icon';
import Footer from '../Footer';
import { ClockIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';
import { UserData } from 'common';

export default function Paywall() {
  const { userData } = useOutletContext<{ userData: any }>(); // This resolves the 'unknown' type issue

  const startedIntroductions = (userData as any)
    const navigate = useNavigate();
  return (
    <>
      <div className="m-10 rounded-lg bg-white border border-gray-200 p-5">
        <div className="flex justify-center items-center pt-5">
          <Icon
            iconClass="w-5 h-5 text-red-400" // Icon class
            className="bg-red-100 w-10 h-10 rounded mx-auto flex items-center justify-center" // Wrapper class for centering
            icon={<ClockIcon />} // Icon to be rendered
            textClass="text-gray-700 text-sm mt-2" // Text class
          />
        </div>
        <div className="sm:w-1/2 w-full mx-auto">
          <h1 className="text-center text-2xl pt-5 font-semibold">
            You've reached the end of your free trial.
          </h1>
          <p className="text-center pt-5">
            Thank you for trying Looop! Upgrade to unlock all features and track
            the <strong>{userData.startedIntroductions} connections</strong>
            you're currently missing.
          </p>
        </div>
        <div className="w-fit mx-auto pt-6">
          <button 
            onClick={ () => navigate('/app/pricing')}
          className="bg-blue-500 w-fit px-4 rounded-lg h-8 flex items-center">
            <Icon
              iconClass="h-4 w-4 text-white mr-2"
              icon={<ShoppingCartIcon />}
            />
            Upgrade Now
          </button>

        </div>
          <p className='py-4 text-center'>Have any feedback? <a className='text-blue-500 underline' href='mailto:info@wellaware.me'>Let us know</a></p>
      </div>
      <Footer/>
    </>
  );
}
