import React from 'react';
/**
 * Feedback describes the interaction of a previous Intro
 */
export default function FeedbackConfirm() {
  return (
    <div>
      <div className="container w-2/5 p-4 mx-auto mt-12 bg-white border border-gray-200 space-y-2 rounded-xl">
        <h1 className="text-2xl font-bold">
          You&apos;ve Filled Out This Feedback Request
        </h1>
        <div className="mt-4 mb-4 border border-t-0 border-gray-200 rounded" />
        <p>Please reach out to cruiz@wellaware.me if you have any questions.</p>
      </div>
    </div>
  );
}
