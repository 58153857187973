import React from 'react';
import PropTypes from 'prop-types';
import Avatars from './Avatars';
import Modal from './Modal';

/**
 * CongratsModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {Function} props.onSubmit - The submit callback function.
 * @param {Function} props.onClose - The close callback function.
 * @param {Object} props.contacts - The contact object.
 * @returns {JSX.Element} The CongratsModal component.
 */
function CongratsModal({ isOpen, onSubmit, onClose, contacts }) {
  const defaultContactValues = { firstName: '-', lastName: '-' };
  const [contactOne, contactTwo] = contacts;
  const { firstName: firstNameOne, lastName: lastNameOne } =
    contactOne || defaultContactValues;
  const { firstName: firstNameTwo, lastName: lastNameTwo } =
    contactTwo || defaultContactValues;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="congrats">
        <h1 className="congrats__title">
          ⁠You’re one step closer to being super connector!
        </h1>
        <h4 className="congrats__subtitle">
          You’ve introduced <br />
          {`${firstNameOne || '-'} ${lastNameOne || '-'}`}
          {` `}
          and {`${firstNameTwo || '-'} ${lastNameTwo || '-'}`}!
        </h4>
        <div className="congrats__contact-profiles">
          <Avatars />
        </div>
        <div className="congrats__description">
          <div className="mt-1 mb-5 border border-t-1 border-gray-200 rounded" />
          <h2 className="congrats__description__text">
            To view this intro’s status or to make more introductions, please
            click below.
          </h2>
        </div>
        <button type="button" className="button__primary" onClick={onClose}>
          View Dashboard
        </button>
      </div>
    </Modal>
  );
}

CongratsModal.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
};

CongratsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  contacts: PropTypes.array.isRequired,
};

export default CongratsModal;
