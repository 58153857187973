import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../icon/Logo.png';
import ProfileDropdown from './ProfileDropdown';
import context from '../exports.json';
import AuthService from '../service/AuthService'
import { useNavigate } from 'react-router';

/**
 * Merge Non null classNames
 */
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/**
 * Navbar component
 */
export default function Navbar({ userData }) {
  const authService = new AuthService(context.ApiGatewayUrl);
  if (!userData) {
    // Optionally, handle the case where userData is not available yet
    return <div>Loading...</div>;
  }

  const navigate = useNavigate();

  const logout = () => {
    authService.logout();
    navigate('/')
  }


  return (
    <Disclosure as="nav" className="bg-white border-b-2 border-gray-200">
      {({ open }) => (
        <>
          <div className="px-2 mx-autos max-w-8xl sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
                <div className="flex items-center flex-shrink-0">
                  <a href="/app/home">
                    <img
                      className="block w-auto h-8 lg:hidden"
                      src={Logo}
                      alt="Looop Logo"
                    />
                    <img
                      className="hidden w-auto h-8 lg:block"
                      src={Logo}
                      alt="Your Company"
                    />
                  </a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4" />
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <label htmlFor="dropdown">
                  <button
                    label="dropdown"
                    type="button"
                    id="dropdown"
                    className="p-1 text-gray-500 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  />
                </label>
                {/* Profile dropdown */}
                <ProfileDropdown
                  userData={ userData }
                  logOut={logout}
                  classNames={classNames}
                />
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1" />
            <a
              href="/app/account"
              className="block px-4 py-2 text-sm text-gray-700 bg-gray-100"
            >
              Your Profile
            </a>
            <button
              type="button"
              onClick={logout}
              className="block px-4 py-2 text-sm text-gray-700 bg-gray-100 w-full"
            >
              Log Out
            </button>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
