/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import propTypes from 'prop-types';

import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';

import moment from 'moment';
import Icon from './Icon';

/**
 * Used as a component for Intro Activity Modal
 */
function HistoryCard({
  onPause,
  onEnd,
  title,
  sendDate,
  remindDate,
  responses,
  content,
}) {
  const [hidden, setHidden] = useState(true);
  /**
   * used to toggle if the state is hidden
   */
  const toggleHidden = () => {
    setHidden(!hidden);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className="p-4 border border-gray-200 rounded-lg hover:bg-gray-50"
      onClick={toggleHidden}
    >
      <div className="inline-flex col-span-1 whitespace-nowrap">
        <div className="m-auto">
          <Icon icon={<CheckCircleIcon />} iconClass="w-6 h-6 text-green-600" />
        </div>
        <div>
          <div className="block space-y-1">
            <h2 className="text-bold">{title} </h2>
            <p className="inline-flex p-1 px-2 my-auto mr-2 text-xs font-bold text-green-700 bg-green-200 rounded-lg text-bold">
              Scheduled - {sendDate.format('MMMM Do YYYY, h:mm:ss a')}
            </p>
            {remindDate && (
              <p className="p-1 px-2 my-auto mr-2 text-xs font-bold text-blue-700 bg-blue-200 rounded-lg text-bold">
                Reminder Scheduled -{' '}
                {remindDate.format('MMMM Do YYYY, h:mm:ss a')}
              </p>
            )}

            {responses !== undefined && (
              <p className="italic text-gray-700">
                {responses}/2 Recipient Responses
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="inline-flex float-right col-span-1">
        <Icon
          className="my-auto ml-auto cursor-pointer"
          icon={hidden ? <ChevronDownIcon /> : <ChevronUpIcon />}
          iconClass="w-5 h-5 text-black"
        />
      </div>
      {!hidden && (
        <div className="mt-4 text-gray-500 space-y-3 mt-5">{content}</div>
      )}
    </div>
  );
}

HistoryCard.propTypes = {
  onPause: propTypes.func.isRequired,
  onEnd: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
  sendDate: propTypes.string.isRequired,
  remindDate: propTypes.string.isRequired,
  content: propTypes.string.isRequired,
  responses: propTypes.number.isRequired,
};

/**
 * HistoryCardcContent
 * @param {feedback} feedback used ot render the history card ontent
 * @returns JSX
 */
function HistoryCardContent({ feedback }) {
  /**
   * Render a Successful Feedback
   * @param {feedback} feedback
   * @returns JSX
   */
  const renderMissingFeedback = (f) => {
    return (
      <div className="py-2">
        <span className="inline">
          <span className="inline-flex items-center justify-center w-6 h-6 text-white bg-gray-400 rounded-full">
            {`${f.recipient.firstName[0]}${f.recipient.lastName[0]}`}
          </span>
          <p className="inline px-2 text-gray-600">{`${f.recipient.firstName} ${f.recipient.lastName}`}</p>
          <p className="inline px-2 text-gray-500 text-sm">
            Waiting for response
          </p>
        </span>
      </div>
    );
  };

  /**
   * Render a Successful Feedback
   * @param {feedback} feedback
   * @returns JSX
   */
  const renderSuccessfulFeedback = (f) => {
    if (f.haveMet) {
      return (
        <>
          <span className="inline">
            <span className="inline-flex items-center justify-center w-6 h-6 text-white bg-green-600 rounded-full">
              {`${f.recipient.firstName[0]}${f.recipient.lastName[0]}`}
            </span>
            <p className="inline px-2 text-gray-900">{`${f.recipient.firstName} ${f.recipient.lastName}`}</p>
            <p className="inline px-2 text-gray-500">{`${moment(
              f.updatedAt
            ).format('MM/DD/YYYY')}`}</p>
          </span>
          <div className="py-4 space-y-4 text-gray">
            <p className="inline-flex p-1 px-6 py-2 text-xs font-bold text-white bg-green-600 rounded-lg text-bold">
              Have Met
            </p>
            <p className="font-bold text-black">
              How was your intro experience?
            </p>
            <p>{f.introExperience}</p>
            <p className="font-bold text-black">
              Was this relationship a good fit?
            </p>
            <p>{f.isGoodFit ? 'Yes' : 'No'}</p>
            <p className="font-bold text-black">
              Was there output to the relationship? Please Explain
            </p>
            <p>{f.notes}</p>
          </div>
        </>
      );
    }

    return (
      <>
        <span className="inline">
          <span className="inline-flex items-center justify-center w-6 h-6 text-white bg-green-600 rounded-full ">
            {`${f.recipient.firstName[0]}${f.recipient.lastName[0]}`}
          </span>
          <p className="inline px-2 text-gray-900">{`${f.recipient.firstName} ${f.recipient.lastName}`}</p>
          <p className="inline px-2 text-gray-500">{`${moment(
            f.updatedAt
          ).format('MM/DD/YYYY')}`}</p>
        </span>
        <div className="py-4 space-y-4 text-gray">
          <p className="inline-flex p-1 px-6 text-xs font-bold text-white bg-red-600 rounded-lg text-bold">
            Have Not Met
          </p>
          <p className="font-bold text-black">Do you intend to meet still?</p>
          <p>{f.intentionToMeet ? 'Yes' : 'No'}</p>
          {f.intentionToMeet && (
            <span>
              <p className="font-bold text-black">
                When do you intend to meet?
              </p>
              <p>{moment(f.dateSet).format('MMMM Do YYYY')}</p>
            </span>
          )}
          <p className="font-bold text-black">
            Was there output to the relationship? Please Explain
          </p>
          <p>{f.notes}</p>
        </div>
      </>
    );
  };

  if (feedback.fullfilled) {
    return renderSuccessfulFeedback(feedback);
  }
  return renderMissingFeedback(feedback);
}

HistoryCardContent.propTypes = {
  feedbackProp: propTypes.object.isRequired,
};

export { HistoryCard, HistoryCardContent };
