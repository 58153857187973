import React, { useEffect, useState } from 'react';

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';

export default function FAQs() {
  const [selected, setSelected] = useState(new Set());

  const onClickChevron = (index) => {
    const newSet = new Set(selected); // Create a new Set instance
    if (newSet.has(index)) {
      newSet.delete(index); // Use delete instead of remove
    } else {
      newSet.add(index);
    }
    setSelected(newSet); // Update the state
  };

  /**
   * The Landing Page
   */
  const FAQS = [
    {
      question: 'Is there a free trial?',
      answer:
        'Yes. You can try out Looop for 30 days, free of charge. Any time after the free trial ends, you are free to view your introduction activity - you just can’t make any new introductions. Also, any active intros that are still awaiting feedback will be seen all the way through, regardless if you choose to pay for full access or not.',
    },
    {
      question: 'What do you do with my contact data?',
      answer:
        'Nothing. We do not interact with your contacts, nor do we do anything with the data surrounding your contacts and the introductions you make with them. In the future, we will look into offering workflows that invite you to refer Looop to your contacts. Additionally, we will invite you to share your data with us for broader analytics. That said, your data is owned by you. Our terms principally allow us to process your data to help you make intros and review feedback.',
    },
    {
      question: 'How exactly are you sending intro emails on my behalf?',
      answer:
        'Essentially, we request Google grant Looop access to draft and send email for you, and that’s all we have access to do. Notice that there’s no “powered by Looop”. Our goal is not to make your introductions about Looop - your intros are about connecting your network. Looop is just focused on doing that in as few clicks-and-types as possible. Beyond that, we simply care that you get feedback in kind for your awareness toward making more and better intros.',
    },
    {
      question: 'How does the referral program work?',
      answer:
        'Well, Looop is an introduction management tool. Whether your introduction recipients end up so intrigued by Looop that they end up becoming paid users, or if you directly refer others to use the software, we’ll give you credit / cash equal to 10% of the subscription your referrals make us! We believe that the best way to thank our partners is to acknowledge their referrals as you would a sales representative doing their job. Alas, if that makes you feel comfortable, ask us about other options, like donating your referral proceeds to a charity of your choice.',
    },
  ];

  return (
    <>
      <div className="text-center">
        <h2 className="pt-16 text-3xl font-bold">Frequently Asked Questions</h2>
        <p className="text-md font-thin pt-5 sm:w-3/12 sm:mx-auto mx-4">
          Find quick answers about our plan, features, and free trial!
        </p>
        <div className="pt-8 space-y-4 pb-32">
          {FAQS.map((f, i) => {
            return (
              <div
                className={`text-md font-thin sm:w-5/12 sm:mx-auto mx-4 pb-4 text-left border-gray-200`}
              >
                <div className="flex items-center justify-between">
                  <h4 className="text-xl font-semibold py-4">{f.question}</h4>
                  <button className="mr-4" onClick={() => onClickChevron(i)}>
                    {selected.has(i) && <ChevronUpIcon className="w-4 h-4" />}
                    {!selected.has(i) && (
                      <ChevronDownIcon className="w-4 h-4" />
                    )}
                  </button>
                </div>
                <div className="pt-4 border-t">
                  {selected.has(i) && (
                    <p className="mr-12 ml-auto">{f.answer}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
