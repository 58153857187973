import { LightBulbIcon } from '@heroicons/react/24/solid';

interface Props {
  text: string;
  className?: string;
}

export default function HelpBanner({ text, className }: Props) {
  return (
    <>
      <div className={`flex bg-amber-50 rounded px-4 py-1 items-center ${className}`}>
        <LightBulbIcon className="w-4 h-4 mr-2 " />

        <span className="italic text-sm">{text}</span>
      </div>
    </>
  );
}
