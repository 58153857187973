import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';

import handleTitleCase from '../helpers/helpers';
/**
 * ProfileDropdown component renders the profile dropdown menu.
 * @returns {JSX.Element} The ProfileDropdown component.
 */
function ProfileDropdown({ userData, logOut, classNames }) {
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2">
          <span className="sr-only">Open user menu</span>
          <span className="bg-white pt-1 mr-3 text-left font-bold">
            {handleTitleCase(userData.firstName)}
          </span>
          <img
            className="w-8 h-8 rounded-full"
            // href="/app/account"
            src={userData.photoUrl}
            alt="My Profile"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 w-48 py-1 mt-2 bg-white shadow-lg origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <a
                href="/app/account"
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700'
                )}
              >
                Your Profile
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={logOut}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                                    'text-left block  w-full px-4 py-2 text-sm text-gray-700'
                )}
              >
                Log out
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

ProfileDropdown.propTypes = {
  userData: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  classNames: PropTypes.func.isRequired,
};

export default ProfileDropdown;
