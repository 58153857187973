import axios, { AxiosInstance } from 'axios';
import { Feedback } from 'common/model';

export default class FeedbackService {
  apiUrl: string;
  axiosInstance: AxiosInstance;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;

    // Create an Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.apiUrl,
    });
  }

  get = async (
    introId: string,
    contactId: string,
  ): Promise<any> => {
    const response = await this.axiosInstance.get(
      `api/feedback/${introId}/${contactId}/`,
      {}
    );
    return response.data;
  };

  save = async (
    introId: string,
    contactId: string,
    recipientId: string,
    attempt: number,
    feedback: Feedback
  ): Promise<any> => {
    const response = await this.axiosInstance.post(
      `api/feedback/`,
      {
        introId,
        contactId,
        recipientId,
        attempt,
        feedback
      }
    );
    return response.data;
  };
}
