import {
  ChatBubbleBottomCenterIcon,
  UserPlusIcon,
} from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import handleTitleCase from '../helpers/helpers';
import IntroStats from './IntroStats';
import context from '../exports.json';

/**
 * Renders the HomeCTA component.
 *
 * @param {object} props - The component props.
 * @param {function} props.renderIntroModal - The function to render the intro modal.
 * @param {function} props.renderNewContactModal - The function to render the new contact modal.
 * @param {function} props.toggleNewContactCard - The function to toggle the new contact card.
 * @returns {JSX.Element} The rendered HomeCTA component.
 */
function HomeCTA({
  userData,
  renderIntroModal,
  renderNewContactModal,
  introductions: listIntros,
  contacts: listContacts,
  showIntroModal,
  showNewContactModal,
}) {
  

  return (
    <div className="home" >
      <div className="home__intro" id='command-panel'>
        <h1  className="home__intro__title">
          
          Good morning, {handleTitleCase(userData.firstName)}!
        </h1>
        <p className="home__intro__description">
          Get ready to upgrade the way you make introductions and connect!
        </p>
        <p className="home__intro__subtitle">
          {'Have any feedback? '}
          <a
            href="https://forms.gle/MZ8paW3kaqXjK5WCA"
            target="_blank"
            className="text-blue-500 underline"
            rel="noreferrer"
          >
            Let us know
          </a>
        </p>
        <div className="mt-5 flex gap-5">
          <button
            type="button"
            onClick={renderIntroModal}
            className="bg-gradient-to-tl from-blue-700 to-blue-500 text-white rounded-lg px-4 py-2 flex items-center hover:bg-blue-700"
          >
            <ChatBubbleBottomCenterIcon className="flex w-5 h-5 mr-2" />
            Create New Intro
          </button>
          <button
            type="button"
            className="bg-gradient-to-tl from-blue-700 to-blue-500 text-white rounded-lg px-4 py-2 flex items-center hover:bg-blue-700"
            onClick={renderNewContactModal}
          >
            <UserPlusIcon className="flex w-5 h-5 mr-2" />
            Add New Contact
          </button>
        </div>
      </div>
      <div className="home-table">
      </div>
    </div>
  );
}

HomeCTA.propTypes = {
  userData: PropTypes.object.isRequired,
  renderIntroModal: PropTypes.func.isRequired,
  renderNewContactModal: PropTypes.func.isRequired,
  introductions: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
  showIntroModal: PropTypes.bool.isRequired,
  showNewContactModal: PropTypes.bool.isRequired,
};

export default HomeCTA;
