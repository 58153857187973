import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

/**
 * Display the user settings in an accordion format.
 */
function AccordionAccount({ children, title, first }) {
  const [openTab, setOpenTab] = useState(false);
  /**
   * callback used when a submit button on the contacts modal is called.
   */
  const handleSetOpenTabs = () => {
    setOpenTab(!openTab);
  };

  return (
    <>
      <button
        type="button"
        className={`w-full p-4 flex justify-between items-center border border-gray-300 h-14 cursor-pointer 
                ${first ? 'rounded-t-lg' : ''} ${
          openTab ? 'bg-gray-200' : 'bg-white'
        }`}
        onClick={handleSetOpenTabs}
      >
        <Icon
          icon={<QuestionMarkCircleIcon />}
          iconClass="w-5 h-5"
          className={`${openTab ? 'text-gray-500' : 'text-black'}`}
          text={title}
        />
        <svg
          data-accordion-icon
          className={`w-3 h-3  shrink-0 ${openTab ? 'rotate-0' : 'rotate-180'}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
      </button>
      {openTab && (
        <div className="p-4 text-gray-500 bg-white border border-t-0 border-gray-300">
          {children}
        </div>
      )}
    </>
  );
}

AccordionAccount.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  first: PropTypes.bool.isRequired,
};

export default AccordionAccount;
