import React, { useState } from 'react';

import Input from '../Input';
import emptyOrBlank from '../../Utilities';
import {
  LightBulbIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/24/solid';
import HelpBanner from '../HelpBanner';

interface Props {
  title: React.Component;
  showHelp: boolean;
  onSkip: () => void;
  onSubmit: () => void;
}

export default function CreateFormContact({ title, showHelp, onSkip, onSubmit }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const inputs = {
    firstName: {
      value: firstName,
      titleText: 'First Name',
      placeholderText: 'First',
      id: 'firstName',
      validationFunc: () => emptyOrBlank(firstName),
      required: true,
      type: 'input',
      onChange: (e) => setFirstName(e.target.value),
    },
    lastName: {
      value: lastName,
      titleText: 'Last Name',
      placeholderText: 'Last',
      id: 'lastName',
      validationFunc: () => emptyOrBlank(lastName),
      required: true,
      type: 'input',
      onChange: (e) => setLastName(e.target.value),
    },
    email: {
      value: email,
      titleText: 'Email',
      placeholderText: 'Email Address',
      id: 'email',
      validationFunc: () => emptyOrBlank(email),
      required: true,
      type: 'input',
      onChange: (e) => setEmail(e.target.value),
    },
  };

  return (
    <>
      <div className="p-6">
        <div className="text-xl font-bold border-b border-text-200 pb-2">
          {title}
        </div>
        <div className="grid grid-cols-2 pt-8 space-x-4">
          <div className="py-4">
            {toComponent(inputs.firstName, setFirstName)}
          </div>

          <div className="py-4">
            {toComponent(inputs.lastName, setLastName)}
          </div>
        </div>

        <div className="py-4">{toComponent(inputs.email, setEmail)}</div>

        {showHelp && 
        <HelpBanner text={`Don't worry, you can always update their details in your dashboard
              anytime.`} />
        }

        <div className="w-full">
          <button 
          onClick={() => { onSubmit(firstName, lastName, email)}}
          className="w-full items-center bg-emerald-700 text-white h-10 mt-6 mb-3 rounded-lg box-border flex justify-center gap-2">
            <UserPlusIcon className="w-4 h-4" />
            <span>Save Contact</span>
          </button>

          <button 
          onClick={() => { onSkip()}}
          className="w-full hover:bg-emerald-100 text-emerald-700 bg-white h-10 rounded-lg box-border">
            Skip Intro Tutorial
          </button>
        </div>
      </div>
    </>
  );
}

function toComponent(input, changeCallback) {
  return (
    <Input
      validationFunc={input.validationFunc}
      titleText={input.titleText}
      placeholderText={input.placeholderText}
      inputChangeCallback={(e) => changeCallback(e.target.value)}
      value={input.value}
      required={input.required}
      type={input.type}
    />
  );
}
