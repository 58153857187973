import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Icon from './Icon';
import Input from './Input';
import emptyOrBlank from '../Utilities';
import AddNewContact from './AddNewContact';

/**
 * Modal Displayed when creating a contact
 */
export default function ContactModal({ isOpen, onSubmit, onClose, contact }) {
  const [error, setError] = useState(undefined);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    setInputValues({
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      occupation: contact.occupation,
      additionalNotes: contact.additionalNotes,
    });
  }, [contact]);

  const inputs = {
    firstName: {
      value: inputValues.firstName,
      titleText: 'First Name',
      placeholderText: 'First',
      id: 'firstName',
      validationFunc: () => emptyOrBlank(inputValues.firstName),
      required: true,
      type: 'input',
    },
    lastName: {
      value: inputValues.lastName,
      titleText: 'Last Name',
      placeholderText: 'Last',
      id: 'lastName',
      validationFunc: () => emptyOrBlank(inputValues.lastName),
      required: true,
      type: 'input',
    },
    email: {
      value: inputValues.email,
      titleText: 'Email',
      placeholderText: 'Email',
      id: 'email',
      validationFunc: () => emptyOrBlank(inputValues.email),
      required: true,
      type: 'input',
    },
    occupation: {
      value: inputValues.occupation,
      titleText: 'Profession',
      placeholderText: 'Title',
      id: 'occupation',
      validationFunc: () => false,
      required: false,
      type: 'input',
    },
    additionalNotes: {
      value: inputValues.additionalNotes,
      titleText: 'Additional contact information',
      placeholderText: '',
      id: 'additionalNotes',
      validationFunc: () => false,
      required: false,
      type: 'textarea',
    },
  };

  /**
   * Update Input Values
   *
   * @param {string} key Key to replace
   * @param {event} e event to replace
   */
  const updateInputValues = (key, e) => {
    // console.log('updating input values', key, e.target.value);
    const newVals = inputValues;
    newVals[key] = e.target.value;
    setInputValues({ ...newVals });
  };

  /**
   * Create an Input React Component using an Object.
   *
   * @param {Object} inputObject
   */
  const constructInput = ({
    value,
    titleText,
    id,
    placeholderText,
    validationFunc,
    required,
    type,
  }) => {
    return (
      <Input
        labelName={id}
        validationFunc={validationFunc}
        titleText={titleText}
        placeholderText={placeholderText}
        inputChangeCallback={(s) => updateInputValues(id, s)}
        value={value}
        required={required}
        type={type}
      />
    );
  };

  /**
   * Shared as configurable callback that targets an input's value
   * @param {event} e we are targetting
   * @parm {function} cb callback called
   */
  const handleInput = (e, cb) => {
    cb(e.target.value);
  };

  /**
   * Callback fired when we are submitting the from
   * Clear State and call callback.
   *
   * We first need to check if our inputs are all good. If they are,
   * we can proceed and clean the state, otherwise we need to render a failure.
   */
  const submitForm = () => {
    /* eslint-disable */
    // console.log('submitting form', inputValues);
    try {
      const asArray = Object.values(inputs).filter((input) => {
        return input.validationFunc() !== false;
      });

      asArray.forEach((input) => {
        input.errorTest = `${input.titleText} is Required.`;
      });

      if (asArray.length > 0) {
        throw new Error(
          'Saving the Contact Failed, Please Ensure all Fields are Valid and Try again'
        );
      }
      onSubmit(
        inputValues.firstName,
        inputValues.lastName,
        inputValues.email,
        inputValues.occupation,
        inputValues.additionalNotes,
        contact.id
      );
      setInputValues({});
      setError();
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  };

  useEffect(() => {
    // setModalState((prevState) => ({
    //   ...prevState,
    //   modalNewContactIsOpen: isOpen,
    // }));
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={isOpen}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="float-right p-4">
                  <button type="button" onClick={onClose}>
                    <Icon
                      className="text-gray-600"
                      iconClass="text-gray-600 h-5 w-5"
                      icon={<XMarkIcon />}
                    />
                  </button>
                </div>
               <AddNewContact constructInput={constructInput} submitForm={submitForm} inputs={inputs} contact={contact} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ContactModal.defaultProps = {
  onSubmit: () => { },
  onClose: () => { },
  contact: {},
};

ContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  contact: PropTypes.objectOf(PropTypes.shape({})),
};
