import { EditContact, CreateContact } from 'common';
import axios, { AxiosInstance } from 'axios';

export default class ContactService {
  apiUrl: string;
  axiosInstance: AxiosInstance;

  constructor(
    apiUrl: string,
    applyInterceptors: (axiosInstance: AxiosInstance) => void
  ) {
    this.apiUrl = apiUrl;

    // Create an Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.apiUrl,
    });

    // Apply interceptors
    applyInterceptors(this.axiosInstance);
  }

  getToken = () => {
    return JSON.parse(localStorage.getItem('tokens') as string).id_token;
  };

  list = async (): Promise<any> => {
    const response = await this.axiosInstance.get('api/contact', {
      headers: {
        Authorization: this.getToken(),
      },
    });
    return response.data;
  };

  create = async (request: CreateContact): Promise<any> => {
    const response = await this.axiosInstance.post('api/contact', request, {
      headers: {
        Authorization: this.getToken(),
      },
    });
    return response.data;
  };

  edit = async (request: EditContact): Promise<any> => {
    // Simulating an async operation, such as sending a PUT request to an API
    const response = await this.axiosInstance.put('api/contact', request, {
      headers: {
        Authorization: this.getToken(),
      },
    });
    return response.data;
  };
}
