/**
 * Quick way to check if string is empty or blank.
 * @param {string} str
 */
const emptyOrBlank = (str) => {
  /* eslint-disable */
  if (str === undefined || str === null || str.length === 0) {
    return 'is Blank!';
  }

  return false;
};

export default emptyOrBlank;
