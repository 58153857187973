import React, { useEffect, useState } from 'react';

import propTypes from 'prop-types';
import { ArrowUturnLeftIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Handlebars from 'handlebars';
import { ContentState, EditorState } from 'draft-js';
import SideBySideEditor from './SideBySideEditor';
import SelectContacts from './SelectContacts';
import HelpBanner from './HelpBanner';

/**
 * Intro Modal Component
 */
export default function ComposeMessageModal({
  title,
  userData,
  defaultBody,
  defaultSubject,
  onComposeSubmit,
  onGoBack,
  selectedContacts,
  onClose,
  setShowSpinner,
  setIntroModal,
  showTutorial,
}) {
  /**
   * Contact to string for text
   */
  const toString = (contact) => {
    if (!contact) return '';

    const firstName = contact.firstName ?? '';
    const lastName = contact.lastName ?? '';

    return `${firstName} ${lastName}`;
  };

  /**
   * Using the Rendered Template Message, lets prepop
   * the text.
   */
  Handlebars.registerHelper('toString', (e) => toString(e));

  const handlebarsContext = {
    contacts: selectedContacts,
    facilitator: {
      firstName: userData.firstName,
      lastName: userData.lastName,
    },
  };
  /**
   * Lazily Set the Editor State.
   */
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(
        Handlebars.compile(defaultBody)(handlebarsContext)
      )
    )
  );

  const [subject, setSubject] = useState(
    Handlebars.compile(defaultSubject)(handlebarsContext)
  );

  /**
   * Handle the Subject Change
   * @param {Event} event when a input change
   */
  const onSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  /**
   * Call the callback with raw text.
   */
  const cleanAndSubmit = () => {
    onComposeSubmit(selectedContacts, subject, editorState.getCurrentContent());
    setIntroModal(false);
    onClose(); // Close the Compose Message modal
    setShowSpinner(true);
  };

  /**
   * Set this to the default State
   */
  const onRefresh = () => {
    setSubject(Handlebars.compile(defaultSubject)(handlebarsContext));
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromText(
          Handlebars.compile(defaultBody)(handlebarsContext)
        )
      )
    );
  };

  /**
   * Set this to the default State local template
   */
  const onRefreshToLocalTemplate = () => {
    setSubject(initialSubject);
    setEditorState(
      EditorState.createWithContent(ContentState.createFromText(initialText))
    );
  };

  return (
    <div>
      <div className="compose-message">
        <h2 className="compose-message__title">{title}</h2>
        <div className="mt-4 mb-4 border border-t-0 border-gray-200 rounded" />
        <div>
          <div className="inline-flex space-x-4">
            <button
              onClick={onGoBack}
              type="button"
              className="flex px-6 py-2 mb-2 text-xs font-semibold bg-transparent border-gray-200 rounded hover:bg-blue-500 hover:text-white hover:border-transparent text-blue-500"
            >
              <ArrowLeftIcon className="w-4 h-4 mr-2" />
              Back to Contact Options
            </button>
          </div>
        </div>
        <SelectContacts
          selectedContacts={{
            first: selectedContacts[0],
            second: selectedContacts[1],
          }}
          onContactRemove={() => {}}
          hideRemoveButtons
        />
        <div className="mt-4 mb-2 border border-t-0 border-gray-200 rounded" />
        <h3 className="compose-message__subtitle">Introduction Message </h3>
        <div className="compose-message__contacts">
          <div className="compose-message__contacts__item">
            <span className="compose-message__contacts__item__label">
              From: &nbsp;
            </span>
            <a className="compose-message__contacts__item__link" href="#">
              {`${userData.email}`}
            </a>
          </div>
          <div className="compose-message__contacts__item">
            <span className="compose-message__contacts__item__label">To: </span>
            <a className="compose-message__contacts__item__link" href="#">
              {selectedContacts.map((c) => toString(c)).join('; ')}
            </a>
          </div>
        </div>
        <div className="compose-message__email-body">
          <div className="mt-6">
            <SideBySideEditor
              setEditorState={setEditorState}
              onChangeSubject={onSubjectChange}
              subjectText={subject}
              editorState={editorState}
              onSubmit={cleanAndSubmit}
              onRefresh={onRefresh}
              context={handlebarsContext}
            />
          </div>
        </div>
        {showTutorial && <HelpBanner
          text="Looking to customize your default email template? You can edit them later in your settings."
          className="my-4"
        /> }

        <div className="flex justify-between items-center border-t pt-2 mt-4">
          <div className="flex items-center h-full">
            <button
              onClick={onRefresh}
              type="button"
              className="flex px-6 py-2 text-xs font-semibold bg-transparent border border-gray-200 rounded hover:bg-blue-500 hover:text-white hover:border-transparent"
            >
              <ArrowUturnLeftIcon className="w-4 h-4 mr-2" />
              Reset to Template Message
            </button>
          </div>
          <div className="flex items-center w-32">
            <button
              onClick={cleanAndSubmit}
              type="button"
              className="w-full items-center bg-emerald-600 text-white h-10 mt-6 mb-3 rounded-lg box-border flex justify-center gap-2"
            >
              Send Intro
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ComposeMessageModal.propTypes = {};
