import React, { useEffect, useState } from 'react';
import './App.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from './component/Navbar';
import context from './exports.json';
import UserDataService from './service/UserDataService';
import { addAuthInterceptor } from './service/AuthInterceptor';
import AuthService from './service/AuthService';

/**
 * The Looop Application
 */
export default function PageWithNavBar() {
  const authService = new AuthService(context.ApiGatewayUrl);
  const tokenInfo = authService.getToken();
  const navigate = useNavigate();
  const userService = new UserDataService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );

  const [userData, setUserData] = useState();

  useEffect(() => {
    if (!tokenInfo) {
      navigate('/login');
      return;
    }

    if (!userData)
    userService.get().then((userData) => {
      setUserData(userData);
    });
  }, [tokenInfo, userData]);

  console.log(userData)
  return (
    <div>
      {userData && (
        <>
          <Navbar userData={userData} />
          <Outlet context={ {userData, setUserData}}/>
        </>
      )}
    </div>
  );
}
