import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../icon/Logo.png';
/**
 * Footer is used at bottom of screen as part of nav
 */
export default function Footer({ hideLinks = false }) {
  return (
    <footer>
      <div className="w-3/4 w-screen mx-auto grid place-items-center space-y-6 container">
        {hideLinks === false && (
          <>
            <div className="flex mt-12 space-x-3">
              <img
                className="w-auto h-8 lg:block"
                src={Logo}
                alt="Your Company"
              />
            </div>
            <p className="text-gray-500">
              Looop: Where connections come full circle.
            </p>

            <div className="grid grid-cols-5 space-x-6">
              {/* <div className="col-span-1">LINK</div> */}
            </div>
          </>
        )}

        <p className="pb-12 text-gray-500">
          &copy; WellAware, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  hideLinks: PropTypes.bool,
};

Footer.defaultProps = {
  hideLinks: false,
};
