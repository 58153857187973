interface IconProps {
  className?: string;
  iconClass?: string;
  icon: React.ReactNode;
  text?: string;
  textClass?: string;
}

export default function Icon({
  className = '',
  iconClass = '',
  icon,
  text,
  textClass = '',
}: IconProps) {
  return (
    <div className={`${className} flex flex-col items-center justify-center`}>
      <div className={`${iconClass}`}>
        {icon}
      </div>
      {text && (
        <p className={textClass}>{text}</p>
      )}
    </div>
  );
}
