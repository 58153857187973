import React from 'react';
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Renders the BackButton component.
 * @returns {JSX.Element} The rendered BackButton component.
 */
function BackButton({ path, page }) {
  const navigate = useNavigate();

  /**
   * Handles the click event to navigate to the about page.
   */
  const handleNavigateTo = () => {
    navigate(path);
  };
  return (
    <div className="grid grid-cols-2">
      <div className="cols-span-4">
        <div className="float-left">
          <button
            onClick={handleNavigateTo}
            type="button"
            className="flex px-4 py-4 mb-2 text-xs font-semibold text-blue-700 bg-transparent rounded hover:bg-blue-500 hover:text-white hover:border-transparent"
          >
            <ArrowLongLeftIcon className="w-4 h-4 mr-2" />
            Return to {page} Page
          </button>
        </div>
      </div>
    </div>
  );
}

BackButton.propTypes = {
  path: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};

export default BackButton;
