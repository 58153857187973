import React from 'react';

import propTypes from 'prop-types';

/**
 * Toast
 */
export default function Toast({
  icon,
  textClass,
  text,
  cta,
  onClose,
  onClick,
}) {
  /**
   * Close and Click
   */
  const chainCloseAndClick = () => {
    onClose();
    onClick();
  };

  return (
    <div
      id="toast-undo"
      className="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow "
      role="alert"
    >
      {icon}
      <div className={textClass}>{text}</div>
      <div className="flex items-center ml-auto space-x-2">
        <button
          type="button"
          onClick={chainCloseAndClick}
          className="text-sm font-medium text-blue-600 p-1.5 hover:bg-blue-100 rounded-lg cursor-pointer"
        >
          {cta}
        </button>
        <button
          type="button"
          className="bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 "
          data-dismiss-target="#toast-undo"
          aria-label="Close"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

Toast.defaultProps = {
  textClass: 'text-sm font-normal',
  icon: <div />,
  cta: 'Undo',
  onClick: () => {},
};

Toast.propTypes = {
  text: propTypes.string.isRequired,
  icon: propTypes.element,
  textClass: propTypes.string,
  cta: propTypes.string,
  onClose: propTypes.func.isRequired,
  onClick: propTypes.func,
};
