import axios, { AxiosInstance } from 'axios';
import { BlackListEntry } from 'common/model';

export default class BlacklistService {
  apiUrl: string;
  axiosInstance: AxiosInstance;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;

    // Create an Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.apiUrl,
    });
  }

  get = async (
    contactId: string,
    facilitatorId: string
  ): Promise<any> => {
    const response = await this.axiosInstance.get(
      `api/blacklist/${facilitatorId}/${contactId}/`,
      {}
    );
    return response.data;
  };

  delete = async (
    contactId: string,
    facilitatorId: string
  ): Promise<any> => {
    const response = await this.axiosInstance.delete(
      `api/blacklist/${facilitatorId}/${contactId}/`,
      {}
    );
    return response.data;
  };


  save = async (
    contactId: string,
    facilitatorId: string,
    isGlobal?: boolean,
  ): Promise<any> => {
    const response = await this.axiosInstance.post(
      `api/blacklist`,
      {
        contactId,
        facilitatorId,
        isGlobal
      }
    );
    return response.data;
  };
}
