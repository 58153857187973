import axios from "axios";
import { jwtDecode } from "jwt-decode";

export default class AuthService {
  static readonly TOKEN_STORAGE_KEY = "tokens";
  static readonly USER_INFO_STORAGE_KEY = "userInfo";
  static readonly REFRESH_ENDPOINT = "api/auth/refresh";

  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  logout = () => {
    localStorage.removeItem(AuthService.TOKEN_STORAGE_KEY);
    localStorage.removeItem(AuthService.USER_INFO_STORAGE_KEY);
  };

  setTokens = async (tokens: any) => {
    const decoded = jwtDecode(tokens.id_token) as any;

    // Store tokens in localStorage
    localStorage.setItem(AuthService.TOKEN_STORAGE_KEY, JSON.stringify(tokens));

    // Extract user information from the decoded ID token
    const userInfo = {
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      email: decoded.email,
      picture: decoded.picture, // Optional field for profile picture
    };

    // Store userInfo in localStorage
    localStorage.setItem(AuthService.USER_INFO_STORAGE_KEY, JSON.stringify(userInfo));
  };

  getToken = () => {
    const storedTokens = localStorage.getItem(AuthService.TOKEN_STORAGE_KEY);

    if (storedTokens) {
      return JSON.parse(storedTokens).id_token;
    }
    return null;
  };

  getUserInfo = () => {
    const userInfo = localStorage.getItem(AuthService.USER_INFO_STORAGE_KEY);

    if (userInfo) {
      return JSON.parse(userInfo);
    }
    return null;
  };

  refreshToken = async () => {
    try {
      const storedTokens = localStorage.getItem(AuthService.TOKEN_STORAGE_KEY);

      if (!storedTokens) {
        throw new Error("No tokens available in localStorage.");
      }

      const tokens = JSON.parse(storedTokens);
      const refreshToken = tokens?.refresh_token;

      if (!refreshToken) {
        throw new Error("Refresh token not available.");
      }

      // Make the /auth/refresh call
      const response = await axios.post(`${this.apiUrl}${AuthService.REFRESH_ENDPOINT}`, {
        refreshToken: refreshToken,
      });

      // Update localStorage with new tokens
      localStorage.setItem(AuthService.TOKEN_STORAGE_KEY, JSON.stringify(response.data));
    } catch (error) {
      console.error("Failed to refresh token:", error);
      throw error; // Rethrow error for interceptor to handle
    }
  };
}
